import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  wrapper: ({ isMobile }) => ({
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(isMobile ? 1 : 2),
    width: "100%",
  }),

  cardWrapper: ({ isMobile }) => ({
    padding: theme.spacing(1),
    minHeight: isMobile ? 200 : "auto",
    minWidth: isMobile ? "100%" : 380,
  }),
}));
