import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";

import Toolbar from "@material-ui/core/Toolbar";

import { makeStyles } from "@material-ui/core/styles";

import { OverflowMenu } from "components";
import Search from "../Search";
import { TabBar, Title, SortMenu, OrgMenu } from "./components";
/*
 sample config
 */
// const config = {
//   title: DefaultStrings.TITLE,
//   onSearch: (value) => {
//     console.debug("onSearch", value);
//   },
//   sort: [
//     {
//       label: "Most Recent",
//       onClick: () => {
//         console.debug("Most Recent");
//       },
//       selected: true,
//       desc: true,
//     },
//     {
//       label: "Name",
//       onClick: () => {
//         console.debug("Name");
//       },
//     },
//   ],
//   extras: [
//     {
//       icon: <EditIcon />,
//       name: "Edit",
//       onClick: () => {
//         console.debug("Edit");
//       },
//     },
//   ],
//   overflow: [
//     {
//       label: "Logout",
//       onClick: () => {
//         console.debug("Logout");
//       },
//       disabled: false,
//     },
//   ],
//   tabs: [
//     {
//       label: "Overview",
//       onClick: () => {
//         console.debug("Overview");
//       },
//     },
//     {
//       label: "Media",
//       onClick: () => {
//         console.debug("Media");
//       },
//     },
//   ],
// };

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: 48,
    height: "100%",
    alignItems: "flex-start",
  },
}));

const MyAppBar = ({ mobile, config, match }) => {
  const hasSearch = !!config?.onSearch;
  const hasOverflow = config?.overflow?.length > 0;
  const hasSort = config?.sort?.length > 0;
  const hasTabs = config?.tabs?.length > 0;
  const hasExtra = config?.extras?.length > 0;
  const title = config?.title;
  const tabIndex = config?.tabs?.findIndex((t) => t.key === config?.tabDefault);

  const [searching, setSearching] = useState(false);
  const classes = useStyles();

  const onTabChange = (event, newTab) => {
    if (config.tabs[newTab].onClick) {
      config.tabs[newTab].onClick(tabIndex, newTab);
    }
  };

  const onSearch = (value) => {
    setSearching(value !== null);
    if (config.onSearch) config.onSearch(value);
  };

  return (
    <AppBar color="inherit" position="sticky">
      <Toolbar className={classes.toolbar}>
        {/* Title */}
        {(!mobile || !searching) && <Title title={title} />}
        {/* Search */}
        {hasSearch && <Search mobile={mobile} onSearch={onSearch} />}
        {(!mobile || !searching) && (
          <>
            {/* Sort */}
            {hasSort && <SortMenu actions={config.sort} />}
            {/* {  */}
            {/* Extra */}
            {hasExtra && (
              <>
                {config.extras.map((extra) => (
                  <IconButton
                    key={extra.name}
                    aria-label={extra.name}
                    color="inherit"
                    onClick={extra.onClick}
                  >
                    {extra.icon}
                  </IconButton>
                ))}
              </>
            )}
            {/* Overflow */}
            {hasOverflow && (
              <OverflowMenu
                options={config.overflow}
                posX="right"
                posY="bottom"
              />
            )}
          </>
        )}
        {/* Organisations */}
        <OrgMenu
          userId={config.userId}
          match={match}
          onCreate={config.orgActions?.onCreateOrg}
        />
      </Toolbar>
      {hasTabs && (
        <TabBar
          mobile={mobile}
          currentTab={tabIndex}
          onTabChange={onTabChange}
          tabs={config.tabs}
        />
      )}
    </AppBar>
  );
};

export default MyAppBar;
