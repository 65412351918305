import { useEffect, useState, useRef, useMemo } from "react";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import {
  checkDomainApi,
  checkUserEmailApi,
  precreateApi,
  createApi,
  updateInfoApi,
  deleteInfoApi,
  removeUserApi,
  leaveApi,
  updateUserRoleApi,
  statsOrganisationApi,
  acceptOrganisationInviteApi,
  declineOrganisationInviteApi,
  getAllOrganisationsApi,
  getMyOrganisationsApi,
  getOrganisationUsersApi,
  inviteUsersApi,
  updateUserInviteApi,
  deleteUsersInviteApi,
  getUsersPendingInviteApi,
  getOrganisationPreviewApi,
} from "./ApiService";
import {
  useSelectorWithCollection,
  useDocument,
  getDocument,
  hasDocument,
  useSubCollectionDocument,
  useCollectionNoStore,
  useCollection,
  useCollectionGroup,
} from "./FirestoreService";
import { isUndefined } from "utils/generalUtils";
import { uploadOrganisationImage } from "./StorageService";
import { usePermissionsOrganisation } from "./PermissionService";
import { useCurrentUserId, useSystemAdmin, useUserDomain } from "./UserService";
import * as Permissions from "utils/permissionUtils";

const DELIMITER = "::";
const COLLECTIONS = {
  USERS_ORGANISATIONS: "users_and_organisations",
  PROJECTS_INFO: "projects_and_info",
  ORGANISATION_INFO: "organisations_and_info",
  USERS: "users",
};

const SUBCOLLECTIONS = {
  ORGANISATION_INVITES: "organisation_invites",
  ORGANISATION_STATISTICS: "organisation_statistics",
};

const DOCUMENTS = {
  DEVICES: "devices",
  SMART_TAGS: "smartTags",
  USERS: "users",
  PROJECTS: "projects",
};

const KEYS = {
  ORGANISATION_ID: "organisationId",
  USER_ID: "usersId",
  IMAGE_URL: "imageURL",
  USER_PERMISSION_KEY: "permissionsKey",
  DELETED: "deleted",
  CREATION_DATE: "creationDate",
  JOIN_STATUS: "status",
  ORGANISATION_INVITE_CODE_USED: "used",
  ORGANISATION_INVITE_STATUS: "status",
};

export const GUEST_ACCESS = {
  ENABLED: "enabled",
  DISABLED: "disabled",
};

export const PROJECT_ACCESS = {
  OPEN: "open",
  PRIVATE: "private",
};

export const PROJECT_JOIN_APPROVAL = {
  AUTO: "auto",
  REQUIRED: "required",
};

export const INVITE_STATUS = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  DECLINED: "declined",
};

const uploadImage = async (organisationId, info) => {
  if (info[KEYS.IMAGE_URL]) {
    const url = await uploadOrganisationImage({
      organisationId,
      file: info[KEYS.IMAGE_URL],
    });

    // update url into project info
    info[KEYS.IMAGE_URL] = url;
  }
};

const getOrganisationInvitesCollection = (organisationId) =>
  `${COLLECTIONS.ORGANISATION_INFO}/${organisationId}/${SUBCOLLECTIONS.ORGANISATION_INVITES}`;

export const checkOrganisationDomain = async (domain) => {
  console.debug("checkOrganisationDomain");
  return await checkDomainApi(domain);
};

export const checkUserEmail = async ({ email, organisationId }) => {
  console.debug("checkUserEmail");
  return await checkUserEmailApi({ email, organisationId });
};

export const createOrganisation = async ({
  name,
  connectedDomains,
  imageURL,
}) => {
  console.debug("createOrganisation");
  const res = await precreateApi(connectedDomains);
  const organisationId = res.result;
  const data = {
    organisationId,
    name,
    connectedDomains,
    ...(imageURL && { imageURL }),
  };
  await uploadImage(organisationId, data);

  return createApi(data);
};

export const updateOrganisation = async ({ organisationId, info }) => {
  // 1. upload new image if given
  await uploadImage(organisationId, info);

  return updateInfoApi({ organisationId, info });
};

// organisations for SuperAdmin
export const getAllOrganisations = ({
  limit,
  orderBy,
  orderDesc,
  searchValue,
  lastKey,
}) => {
  return getAllOrganisationsApi({
    limit,
    orderBy,
    orderDesc,
    searchValue,
    lastKey,
  });
};

export const useTotalOrganisationCountSuperAdmin = (doRequest) => {
  const collection = doRequest && COLLECTIONS.ORGANISATION_INFO;
  const where = [[KEYS.DELETED, "==", false]];

  const docs = useCollection({ collection, where });
  if (!doRequest) return;
  if (isUndefined(docs)) return docs;

  return docs ? Object.values(docs).filter((d) => d).length : 0;
};

export const useTotalUserOrganisationsCount = (userId) => {
  const storeAsTotalUserOrgsCount = `useTotalUserOrganisationsCount${DELIMITER}${userId}`;
  const configs = {
    collection: COLLECTIONS.USERS_ORGANISATIONS,
    where: [
      [KEYS.USER_ID, "==", userId],
      [
        KEYS.USER_PERMISSION_KEY,
        "in",
        [
          Permissions.ORGANISATION_PERMISSIONS.CREATOR,
          Permissions.ORGANISATION_PERMISSIONS.ADMIN,
          Permissions.ORGANISATION_PERMISSIONS.VIEWER,
        ],
      ],
    ],
    storeAs: storeAsTotalUserOrgsCount,
    populates: [
      {
        child: KEYS.ORGANISATION_ID,
        root: COLLECTIONS.ORGANISATION_INFO,
      },
    ],
  };

  useFirestoreConnect(userId && configs);
  const userAndOrgs = useSelectorWithCollection(storeAsTotalUserOrgsCount);
  const orgs = useSelectorWithCollection(COLLECTIONS.ORGANISATION_INFO);

  if (isUndefined(userAndOrgs)) return userAndOrgs;
  if (userAndOrgs === null) return 0;

  const res = Object.values(userAndOrgs).reduce((prev, userAndOrg) => {
    if (userAndOrg === null) return prev;

    const orgId =
      KEYS.ORGANISATION_ID in userAndOrg && userAndOrg[KEYS.ORGANISATION_ID];
    const currOrg = orgs[orgId];

    // no organisation with id
    if (!currOrg) return prev;
    // organisation deleted
    if (currOrg[KEYS.DELETED] === true) return prev;

    return (prev += 1);
  }, 0);

  return res;
};

// organisations for user
export const getMyOrganisations = (params) => {
  return getMyOrganisationsApi(params);
};

export const getOrganisationUsers = (params) => {
  return getOrganisationUsersApi(params);
};

export const getOrganisationInfo = (organisationId) =>
  getDocument({
    collection: COLLECTIONS.ORGANISATION_INFO,
    doc: organisationId,
  });

export const useOrganisationInfo = (organisationId) =>
  useDocument({
    collection: organisationId && COLLECTIONS.ORGANISATION_INFO,
    storeAs: `${COLLECTIONS.ORGANISATION_INFO}${DELIMITER}${organisationId}`,
    doc: organisationId && organisationId,
  });

// list of userId => array
export const useOrganisationUserIds = (organisationId, userPermissionKey) => {
  const firestore = useFirestore();
  const [userIds, setUserIds] = useState();

  useEffect(() => {
    if (organisationId) {
      return firestore
        .collection(COLLECTIONS.USERS_ORGANISATIONS)
        .where(KEYS.ORGANISATION_ID, "==", organisationId)
        .where(KEYS.USER_PERMISSION_KEY, "in", userPermissionKey)
        .onSnapshot(
          (res) => {
            setUserIds(res.docs.map((doc) => doc.data().usersId));
          },
          (error) => {}
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, organisationId]);

  return userIds;
};

export const useOrganisationUsersCount = (organisationId) => {
  const userPermissionKey = [
    Permissions.ORGANISATION_PERMISSIONS.CREATOR,
    Permissions.ORGANISATION_PERMISSIONS.ADMIN,
    Permissions.ORGANISATION_PERMISSIONS.VIEWER,
  ];

  return useOrganisationUserIds(organisationId, userPermissionKey)?.length;
};

export const useOrganisationGuestsCount = (organisationId) => {
  const userPermissionKey = [Permissions.ORGANISATION_PERMISSIONS.GUEST];
  return useOrganisationUserIds(organisationId, userPermissionKey)?.length;
};

export const deleteOrganisation = (organisationId) => {
  return deleteInfoApi(organisationId);
};

/**
 * Organisation info/settings
 */

// construct and id from given userId and projectId
const constructUserOrganisationId = ({ userId, organisationId }) =>
  userId && organisationId && `${organisationId}_${userId}`;

/**
 * Organisation user management
 */
export const isUserInOrganisation = async ({
  userId,
  organisationId,
  excludeDeleted = true, // keep the flexibility of being able to include/exclude deleted organisation.
}) => {
  if (!userId || !organisationId) return false;
  const doc = constructUserOrganisationId({ organisationId, userId });
  if (!doc) return false;

  const promises = [
    getOrganisationInfo(organisationId),
    hasDocument({
      collection: COLLECTIONS.USERS_ORGANISATIONS,
      doc,
    }),
  ];

  const [organisationInfo, userInOrganisation] = await Promise.all(promises);
  return !userInOrganisation
    ? false // user not in organisation = false
    : organisationInfo?.deleted && excludeDeleted
    ? false // organisation is deleted and exclude = false
    : true; // otherwise true
};

export const getUserInOrganisation = async ({
  userId,
  organisationId,
  excludeDeleted = true, // keep the flexibility of being able to include/exclude deleted organisation.
}) => {
  const isInOrganisation = await isUserInOrganisation({
    userId,
    organisationId,
    excludeDeleted,
  });

  if (isInOrganisation) {
    return await getDocument({
      collection: COLLECTIONS.USERS_ORGANISATIONS,
      doc: constructUserOrganisationId({ organisationId, userId }),
    });
  }

  return null;
};

// use permission of user to an organisation
export const useUserOrganisationPermissions = ({ userId, organisationId }) => {
  const isSuperAdmin = useSystemAdmin();

  const doc = constructUserOrganisationId({ organisationId, userId });
  const info = useDocument({
    collection:
      typeof isSuperAdmin !== "undefined" &&
      organisationId &&
      COLLECTIONS.USERS_ORGANISATIONS,
    doc,
  });
  const permissions = usePermissionsOrganisation();

  const [userPermissions, setUserPermissions] = useState();
  const [infoKey, setInfoKey] = useState(null);

  useEffect(() => {
    if (info) {
      const key = info[KEYS.USER_PERMISSION_KEY];

      if (!key) {
        console.warn(`${KEYS.USER_PERMISSION_KEY} key missing for ${doc}`);
        return;
      }

      setInfoKey(key);
      setUserPermissions(null);
    }
    if (info === null) {
      setUserPermissions(null);
    }
  }, [doc, info, organisationId]);

  useEffect(() => {
    if (!permissions) return;

    // Admin permissions for SuperAdmin
    if (isSuperAdmin && !userPermissions) {
      setUserPermissions(
        permissions[Permissions.ORGANISATION_PERMISSIONS.ADMIN]
      );
    }

    if (!infoKey) return;

    if (!(infoKey in permissions)) {
      console.warn(`Unknown ${KEYS.USER_PERMISSION_KEY} key`);
      return;
    }

    if (!userPermissions) {
      setUserPermissions(permissions[infoKey]);
    }
  }, [infoKey, isSuperAdmin, organisationId, permissions, userPermissions]);

  const canReadUsers =
    isSuperAdmin || Permissions.canReadUsers(userPermissions);
  const canDeleteUsers =
    isSuperAdmin || Permissions.canDeleteUsers(userPermissions);
  const canWriteUserPermissions =
    isSuperAdmin || Permissions.canWriteUserPermissions(userPermissions);
  const canReadUserPermissions =
    isSuperAdmin || Permissions.canReadUserPermissions(userPermissions);
  const canDeleteOrganisation =
    isSuperAdmin || Permissions.canDeleteOrganisation(userPermissions);

  const canWriteOrganisation =
    isSuperAdmin || Permissions.canWriteOrganisation(userPermissions);
  const canReadOrganisation =
    isSuperAdmin || Permissions.canReadOrganisation(userPermissions);
  const canReadProjects =
    isSuperAdmin || Permissions.canReadProjects(userPermissions);
  const canWriteProjects =
    isSuperAdmin || Permissions.canWriteProjects(userPermissions);
  const canReadDevices =
    isSuperAdmin || Permissions.canReadDevices(userPermissions);
  const canWriteMessage =
    isSuperAdmin || Permissions.canWriteMessages(userPermissions);
  const isUserAdmin = useMemo(() => {
    if (info === null) return false;

    if (
      isSuperAdmin ||
      typeof userPermissions === "undefined" ||
      userPermissions === null
    )
      return userPermissions;

    return (
      userPermissions.permissionsKey ===
      Permissions.ORGANISATION_PERMISSIONS.ADMIN
    );
  }, [info, isSuperAdmin, userPermissions]);

  if (
    typeof isSuperAdmin === "undefined" &&
    typeof userPermissions === "undefined"
  )
    return {};

  return {
    // devices
    canReadDevices,
    // messages
    canWriteMessage,
    // organisation
    canReadOrganisation,
    canWriteOrganisation,
    canDeleteOrganisation,
    // projects
    canReadProjects,
    canWriteProjects,
    // user permisssions
    canWriteUserPermissions,
    canReadUserPermissions,
    // users
    canReadUsers,
    canDeleteUsers,

    userPermissions,
    isUserAdmin,
    isSuperAdmin,
  };
};

export const deleteOrganisationUser = ({ organisationId, userId }) =>
  removeUserApi({ organisationId, userId });

export const updateOrganisationUserRole = ({ organisationId, userId, role }) =>
  updateUserRoleApi({ organisationId, userId, role });

export const acceptOrganisationInvite = ({ organisationId, userId, code }) =>
  acceptOrganisationInviteApi({ organisationId, userId, code });

export const declineOrganisationInvite = ({ organisationId, userId, code }) =>
  declineOrganisationInviteApi({ organisationId, userId, code });

export const leaveOrganisation = ({ organisationId, userId }) =>
  leaveApi({ organisationId, userId });

export const useUserPendingInvites = ({ userId, code }) => {
  const inviteDocs = useCollectionGroup({
    collection: userId && SUBCOLLECTIONS.ORGANISATION_INVITES,
    where: [
      [KEYS.USER_ID, "==", userId],
      [KEYS.ORGANISATION_INVITE_STATUS, "==", INVITE_STATUS.PENDING],
    ],
  });

  if (typeof inviteDocs === "undefined") return inviteDocs;
  if (inviteDocs === null) return [];

  let invites = Object.values(inviteDocs);

  if (code) {
    invites = invites.filter((invite) => invite.code === code);
  }

  return invites;
};

// use if user is already inside a project
// use current user if userId not provided
//
// return undefined = loading
export const useUserInOrganisation = ({ userId, organisationId }) => {
  const mounted = useRef(false);
  const currentUserId = useCurrentUserId();
  const id = userId || currentUserId;

  const doc = constructUserOrganisationId({
    organisationId: organisationId,
    userId: id,
  });
  const [isInOrganisation, setIsInOrganisation] = useState();
  const userAndOrg = useDocument({
    collection: isInOrganisation && COLLECTIONS.USERS_ORGANISATIONS,
    doc,
  });

  useEffect(() => {
    mounted.current = true;
    if (doc) {
      hasDocument({ collection: COLLECTIONS.USERS_ORGANISATIONS, doc }).then(
        (res) => {
          if (mounted.current) setIsInOrganisation(res);
        }
      );
    }
    return () => {
      mounted.current = false;
    };
  });

  return userAndOrg;
};

export const useUserAdmin = ({ userId, organisationId }) => {
  const userInOrg = useUserInOrganisation({
    userId,
    organisationId,
  });

  if (typeof userInOrg === "undefined" || userInOrg === null) return userInOrg;

  return (
    userInOrg?.permissionsKey === Permissions.ORGANISATION_PERMISSIONS.ADMIN
  );
};

export const useUserHasConnectedDomain = (organisationId) => {
  const userDomain = useUserDomain();
  const userInOrg = useOrganisationInfo(organisationId);
  const orgInfo = useOrganisationInfo(userInOrg && organisationId);

  return orgInfo && orgInfo?.connectedDomains?.includes(userDomain);
};

export const useGetOrganisationStats = (organisationId) => {
  const [stats, setStats] = useState(null);
  const mounted = useRef(false);

  useEffect(() => {
    const fetchData = () => {
      mounted.current = true;

      if (mounted.current && organisationId) {
        statsOrganisationApi(organisationId).then((res) => {
          if (mounted.current) {
            setStats(res.result);
          }
        });
      }
    };

    fetchData();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  return stats;
};

export const inviteUsers = async ({ organisationId, invites }) => {
  return await inviteUsersApi({ organisationId, invites });
};

export const updateUserInvite = async ({ organisationId, code, role }) => {
  return await updateUserInviteApi({ organisationId, code, role });
};

export const deleteUsersInvite = async ({ organisationId, code }) => {
  return await deleteUsersInviteApi({ organisationId, code });
};

export const getUsersPendingInvite = async ({
  organisationId,
  searchValue,
  lastKey,
  limit,
  orderBy,
  ordeDesc,
}) => {
  return await getUsersPendingInviteApi({
    organisationId,
    searchValue,
    lastKey,
    limit,
    orderBy,
    ordeDesc,
  });
};

export const getOrganisationPreview = async ({
  inviteCode,
  organisationId,
}) => {
  return await getOrganisationPreviewApi({ inviteCode, organisationId });
};

export const useUsersPendingInvitesCount = (organisationId) => {
  const collection =
    organisationId && getOrganisationInvitesCollection(organisationId);
  const where = [
    [KEYS.ORGANISATION_INVITE_STATUS, "==", INVITE_STATUS.PENDING],
  ];

  const docs = useCollectionNoStore({
    collection,
    where,
    // we just want the count so simply set document to true
    processData: () => true,
  });
  if (isUndefined(docs)) return docs;
  return docs ? Object.values(docs).filter((d) => d).length : 0;
};

export const useUserPendingInvite = ({ code, organisationId }) => {
  const invite = useSubCollectionDocument({
    collection: organisationId && code && COLLECTIONS.ORGANISATION_INFO,
    doc: organisationId,
    subcollection: SUBCOLLECTIONS.ORGANISATION_INVITES,
    subdoc: code,
  });

  return invite;
};

export const useOrganisationDevicesStatistics = (organisationId) => {
  return useSubCollectionDocument({
    collection: organisationId && COLLECTIONS.ORGANISATION_INFO,
    doc: organisationId,
    subcollection: SUBCOLLECTIONS.ORGANISATION_STATISTICS,
    subdoc: DOCUMENTS.DEVICES,
  });
};

export const useOrganisationSmartTagsStatistics = (organisationId) => {
  return useSubCollectionDocument({
    collection: organisationId && COLLECTIONS.ORGANISATION_INFO,
    doc: organisationId,
    subcollection: SUBCOLLECTIONS.ORGANISATION_STATISTICS,
    subdoc: DOCUMENTS.SMART_TAGS,
  });
};

export const useOrganisationUsersStatistics = (organisationId) => {
  return useSubCollectionDocument({
    collection: organisationId && COLLECTIONS.ORGANISATION_INFO,
    doc: organisationId,
    subcollection: SUBCOLLECTIONS.ORGANISATION_STATISTICS,
    subdoc: DOCUMENTS.USERS,
  });
};

export const useOrganisationProjectsStatistics = (organisationId) => {
  return useSubCollectionDocument({
    collection: organisationId && COLLECTIONS.ORGANISATION_INFO,
    doc: organisationId,
    subcollection: SUBCOLLECTIONS.ORGANISATION_STATISTICS,
    subdoc: DOCUMENTS.PROJECTS,
  });
};
