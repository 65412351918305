import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";

import { OrganisationStrings, ProjectStrings } from "strings";
import { ROUTES } from "route";
import { ORGANISATION_SORT_OPTION_FIELD } from "utils/organisationUtils";

export const PROJECT_SORT_OPTION_FIELD = {
  SORT_OPTION_NAME: "name",
  SORT_OPTION_MOSTRECENT: "modified_date",
  SORT_OPTION_BRAND: "brand",
  SORT_OPTION_DATE: "creation_date",
};

const tabsConfig = {
  ORGANISATIONS: {
    label: OrganisationStrings.ADMIN_TITLE_ORGANISATIONS,
    route: ROUTES.ADMIN_ORGANISATIONS,
  },
  PROJECTS: {
    label: OrganisationStrings.ADMIN_TITLE_PROJECTS,
    route: ROUTES.ADMIN_PROJECTS,
  },
};

// return an array of tabs to be used by MyAppBar
export const useTabs = () => {
  const history = useHistory();

  return Object.entries(tabsConfig).map(([key, cfg]) => ({
    key,
    label: cfg.label,
    onClick: () => history.push(cfg.route),
  }));
};

export const usePageConfig = ({
  path,
  sortDesc,
  sortValue,
  onCreate,
  onSearch,
  onSort,
}) => {
  const { organisationId } = useParams();

  const tabs = useTabs(organisationId);

  const projectSortOptions = useMemo(
    () =>
      Object.entries(PROJECT_SORT_OPTION_FIELD).map(([key, value]) => ({
        label: ProjectStrings[key],
        desc: sortDesc,
        selected: value === sortValue,
        onClick: () => onSort(value),
      })),
    [onSort, sortDesc, sortValue]
  );

  const organisationSortOptions = useMemo(
    () =>
      Object.entries(ORGANISATION_SORT_OPTION_FIELD).map(([key, value]) => ({
        label: ProjectStrings[key],
        desc: sortDesc,
        selected: value === sortValue,
        onClick: () => onSort(value),
      })),
    [onSort, sortDesc, sortValue]
  );

  switch (path) {
    case ROUTES.ADMIN_ORGANISATIONS: {
      return {
        config: {
          appBar: {
            title: OrganisationStrings.ADMIN_TITLE_ORGANISATIONS,
            tabDefault: "ORGANISATIONS",
            tabs,
            sort: organisationSortOptions,
            onSearch: (value) =>
              value && value.length > 0 ? onSearch(value) : onSearch(null),
          },
          actionButton: {
            actions: [
              {
                icon: <AddToQueueOutlinedIcon />,
                name: OrganisationStrings.ADD_DIALOG_CREATE,
                onClick: onCreate,
              },
            ],
          },
        },
      };
    }
    case ROUTES.ADMIN_PROJECTS: {
      return {
        config: {
          appBar: {
            title: OrganisationStrings.ADMIN_TITLE_PROJECTS,
            tabDefault: "PROJECTS",
            tabs,
            sort: projectSortOptions,
            onSearch: (value) =>
              value && value.length > 0 ? onSearch(value) : onSearch(null),
          },
        },
      };
    }
    default: {
      return {};
    }
  }
};
