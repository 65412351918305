import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
  forwardRef,
} from "react";
import { useParams } from "react-router-dom";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
import SearchIcon from "@material-ui/icons/Search";

import { getAllProjects } from "services/ApiService";
import { useMobileLayout } from "hooks/uiHooks";
import { useStyles } from "./style";
import { ProjectStrings } from "strings";
import Spinner from "ui/Spinner";
import ProjectCard from "ui/cards/projectCard/ProjectCard";
import { PROJECT_SORT_OPTION_FIELD, PROJECT_SORT_OPTION } from "../config";
import InfiniteScroll from "ui/InfiniteScroll";
import { EmptyData } from "ui/emptyData";
import { useProjectUsersLastUpdate } from "services/UserService";

const Projects = forwardRef((props, ref) => {
  const { organisationId } = useParams();
  const [startAfter, setStartAfter] = useState(null);
  const [projectsIds, setProjectsIds] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [fetchedAt, setFetchedAt] = useState();

  const projectUsersLastUpdate = useProjectUsersLastUpdate();

  const mobile = useMobileLayout();
  const classes = useStyles({ mobile, small: false });

  const resetData = () => {
    setIsFetchedAll(false);
    setIsRequestSent(true);
    setStartAfter(null);
  };

  const getParams = useCallback(
    (params) => {
      const { searchValue, sortDesc, lastKey } = params;
      const orderBy =
        params.sortValue && params.sortValue in PROJECT_SORT_OPTION_FIELD
          ? PROJECT_SORT_OPTION_FIELD[params.sortValue]
          : PROJECT_SORT_OPTION_FIELD[PROJECT_SORT_OPTION[0]];

      return {
        organisationId,
        limit: 10,
        orderBy,
        searchValue,
        lastKey,
        orderDesc: sortDesc,
      };
    },
    [organisationId]
  );

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        ...props,
        ...params,
      });

      try {
        const response = await getAllProjects(requestParams);
        const items = response.result.items;

        setFetchedAt(response.result.fetchedAt);

        if (items.length === 0) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setProjectsIds([...projectsIds, ...items]);
        } else {
          setProjectsIds(items);
          setIsRequestSent(false);
        }

        if (items.length) {
          setStartAfter(items[items.length - 1]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [getParams, isRequestSent, projectsIds, props]
  );

  useEffect(() => {
    // initial request on page loaded
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // do request if project users last update date changed
    if (projectUsersLastUpdate > fetchedAt) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUsersLastUpdate, fetchedAt]);

  useImperativeHandle(
    ref,
    () => ({
      doRequest: (opt) => {
        resetData();
        fetchData({ ...opt });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const configCard = {
    showParticipants: true,
    clickToOpen: true,
  };

  const onLoadMore = useCallback(() => {
    if (isFetchedAll) return;
    fetchData({ lastKey: startAfter });
  }, [fetchData, isFetchedAll, startAfter]);

  const config = {
    onLoadMore: onLoadMore,
  };

  const renderContent = () => {
    if (typeof projectsIds === "undefined") return <Spinner />;

    if (!projectsIds.length && !isRequestSent)
      return (
        <EmptyData
          title={
            props.searchValue
              ? ProjectStrings.LIST_NO_FOR_SEARCH
              : ProjectStrings.LIST_NO_PROJECTS
          }
          icon={
            props.searchValue ? <SearchIcon /> : <ViewCarouselOutlinedIcon />
          }
        />
      );

    return (
      <InfiniteScroll config={config} size={projectsIds.length}>
        <div className={classes.main}>
          {projectsIds.map((projectId) => (
            <div key={projectId} className={classes.cardContainer}>
              <ProjectCard projectId={projectId} config={configCard} />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    );
  };

  return <div className={classes.root}>{renderContent()}</div>;
});

export default Projects;
