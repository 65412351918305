import { useMemo } from "react";

import { useCollection } from "./FirestoreService";
import { useUserProjectPermissions, useProjectInfo } from "./ProjectService";
import { useUserOrganisationPermissions } from "./OrganisationService";
import { getCurrentUserId, useCurrentUserId } from "./UserService";
import {
  ORGANISATION_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "utils/permissionUtils";

import { COLLECTIONS } from "../utils/firestoreUtils";

export const PERMISSION_KEYS = {
  ANALYTICS: "analytics",
  CODE: "code", // project share code
  CONTENT: "content", // project media content
  DEVICE_DEPLOYMENT: "deviceDeployment", // deviceDeployment is not used
  DEVICE_EXTRA: "deviceExtra",
  DEVICES: "devices",
  PROJECT: "project",
  PROJECT_APPROVALS: "projectApprovals",
  PROJECT_EXTRA: "projectExtra",
  PROJECT_MEDIA: "projectMedia",
  USER_PERMISSIONS: "userPermissions",
  USERS: "users",
  MESSAGE: "message",
};

const PERMISSION_ACTIONS = {
  READ: "read",
  WRITE: "write",
  DELETE: "delete",
};

const KEYS = {
  NAME: "name",
  ORDER: "order",
  DESCRIPTION: "description",
  PERMISSION_KEY: "permissionsKey",
  CHAT_ID: "id",
  CHAT_USER_ID: "uid",
  DELETED: "deleted",
};

const getPermission = (data, key) => data?.[key];

const usePermissionProject = (projectId) =>
  useUserProjectPermissions({ userId: getCurrentUserId(), projectId })
    .userPermissions;

const usePermissionOrganisation = (organisationId) =>
  useUserOrganisationPermissions({
    userId: getCurrentUserId(),
    organisationId,
  }).userPermissions;

/**
 * System permissions
 */

// get system-wide permission definitions => dict
export const usePermissionsProject = () =>
  useCollection({ collection: COLLECTIONS.PERMISSIONS });

export const usePermissionsOrganisation = () =>
  useCollection({ collection: COLLECTIONS.ORGANISATION_PERMISSIONS });

export const useProjectBasicPermissionsOptions = () => {
  const basicPermissions = usePermissionsProject();

  if (typeof basicPermissions === "undefined" || basicPermissions === null)
    return basicPermissions;

  return Object.values(basicPermissions)
    .filter((perm) => perm[KEYS.DELETED] === false)
    .map((perm) => ({
      title: perm[KEYS.NAME],
      value: perm[KEYS.PERMISSION_KEY],
    }))
    .sort((a, b) => b[KEYS.ORDER] - a[KEYS.ORDER]);
};

export const usePermissionsBasicProject = (projectId) => {
  const projInfo = useProjectInfo({ userId: getCurrentUserId(), projectId });
  const { userPermissions, isSuperAdmin } = useUserOrganisationPermissions({
    userId: getCurrentUserId(),
    organisationId: projInfo?.organisationId,
  });
  const userPermission = usePermissionProject(projectId);
  const permissions = usePermissionsProject();

  const buildPermissions = (progPerms, orderValue) => {
    return progPerms
      .filter((p) => p[KEYS.ORDER] >= orderValue)
      .map((p) => ({
        [KEYS.NAME]: p[KEYS.NAME],
        [KEYS.ORDER]: p[KEYS.ORDER],
        [KEYS.PERMISSION_KEY]: p[KEYS.PERMISSION_KEY],
      }))
      .sort((a, b) => a[KEYS.ORDER] - b[KEYS.ORDER]);
  };

  const basicPermissions = useMemo(() => {
    if (typeof permissions === "undefined" || permissions === null)
      return permissions;

    let projPermissions = Object.values(permissions).filter(
      (p) => p[KEYS.DELETED] === false
    );

    if (projPermissions.length === 0) return projPermissions;

    // initial order is a last item from basic project permissions
    let permissionsOrder =
      projPermissions[projPermissions.length - 1][KEYS.ORDER];

    // SuperAdmin permisssions
    if (isSuperAdmin) {
      return buildPermissions(projPermissions, 0);
    }

    // User is Admin in the organsiation
    if (
      userPermissions &&
      userPermissions[KEYS.PERMISSION_KEY] === ORGANISATION_PERMISSIONS.ADMIN
    ) {
      permissionsOrder = permissions[PROJECT_PERMISSIONS.ADMIN][KEYS.ORDER];
      return buildPermissions(projPermissions, permissionsOrder);
    }

    if (typeof userPermission === "undefined" || userPermission === null)
      return userPermission;

    if (userPermission) {
      permissionsOrder = userPermission[KEYS.ORDER];
    }

    return buildPermissions(projPermissions, permissionsOrder);
  }, [isSuperAdmin, permissions, userPermission, userPermissions]);

  return basicPermissions;
};

// export const useOrganisationBasicPermissionsOptions = () => {
//   const basicPermissions = usePermissionsOrganisation();

//   if (typeof basicPermissions === "undefined" || basicPermissions === null)
//     return basicPermissions;

//   return Object.values(basicPermissions)
//   .filter((p) => p[KEYS.ORDER] >= userPermission[KEYS.ORDER])
//   .map((perm) => ({
//       title: perm[KEYS.NAME],
//       value: perm[KEYS.PERMISSION_KEY],
//     }))
//     .sort((a, b) => b[KEYS.ORDER] - a[KEYS.ORDER]);
// };

export const useBasicPermissionsOrganisation = () => {
  const permissions = usePermissionsOrganisation();

  if (typeof permissions === "undefined" || permissions === null)
    return permissions;

  return Object.values(permissions)
    .map((perm) => ({
      [KEYS.NAME]: perm[KEYS.NAME],
      [KEYS.ORDER]: perm[KEYS.ORDER],
      [KEYS.PERMISSION_KEY]: perm[KEYS.PERMISSION_KEY],
    }))
    .sort((a, b) => a[KEYS.ORDER] - b[KEYS.ORDER]);
};

export const useTeamOrganisationPermissionsOptions = () => {
  const basicPermissions = useBasicPermissionsOrganisation();

  return (
    basicPermissions
      ?.filter(
        (perm) => perm[KEYS.PERMISSION_KEY] !== ORGANISATION_PERMISSIONS.GUEST
      )
      .map((perm) => ({
        title: perm[KEYS.NAME],
        value: perm[KEYS.PERMISSION_KEY],
      })) || []
  );
};

export const useGuestOrganisationPermissionsOptions = () => {
  const basicPermissions = useBasicPermissionsOrganisation();

  return (
    basicPermissions
      ?.filter(
        (perm) => perm[KEYS.PERMISSION_KEY] === ORGANISATION_PERMISSIONS.GUEST
      )
      .map((perm) => ({
        title: perm[KEYS.NAME],
        value: perm[KEYS.PERMISSION_KEY],
      })) || []
  );
};

export const useUserHasHigherRole = ({ projectId, userId, targetUserId }) => {
  const { userPermissions: userRole } = useUserProjectPermissions({
    projectId,
    userId,
  });
  const { userPermissions: targetRole } = useUserProjectPermissions({
    projectId,
    userId: targetUserId,
  });

  return (
    userRole && targetRole && userRole[KEYS.ORDER] <= targetRole[KEYS.ORDER]
  );
};

/**
 * Project Media
 */

// allow to edit project media if:
// 1) content, or
// 2) projectMedia
//
// should cover all required permissions below
export const useCanEditProjectMedia = (projectId) => {
  const data = usePermissionProject(projectId);
  const content = getPermission(data, PERMISSION_KEYS.CONTENT);
  const projectMedia = getPermission(data, PERMISSION_KEYS.PROJECT_MEDIA);
  return (
    (content?.read && content?.write) ||
    (projectMedia?.read && projectMedia?.write)
  );
};

// in sync with rules of /projects/projects_and_info
export const useProjectAppsPermission = (projectId) => {
  const data = usePermissionProject(projectId);
  return getPermission(data, PERMISSION_KEYS.CONTENT);
};

// currently from storage and not protected by security rules
// using CONTENT until then
export const useProjectContentPermission = (projectId) => {
  const data = usePermissionProject(projectId);
  return getPermission(data, PERMISSION_KEYS.CONTENT);
};

// in sync with rules of /projects_and_info/{projectId}/media/
export const useProjectResolutionPermission = (projectId) => {
  const data = usePermissionProject(projectId);
  return getPermission(data, PERMISSION_KEYS.PROJECT_MEDIA);
};

export const useCanEditAndDeleteMediaLink = ({ projectId, linkCreatorId }) => {
  const userId = useCurrentUserId();
  return useUserHasHigherRole({
    projectId,
    userId,
    targetUserId: linkCreatorId,
  });
};

/**
 * Chatroom
 */

export const useCanDeleteMessage = ({ message, projectId, organisationId }) => {
  const userId = useCurrentUserId();
  const projData = usePermissionProject(projectId && projectId);
  const orgData = usePermissionOrganisation(organisationId && organisationId);

  if ((!message || !projectId || !organisationId) && !projData && !orgData)
    return false;
  if (userId === message?.[KEYS.CHAT_USER_ID]) return true;
  if (projData) {
    return getPermission(projData, PERMISSION_KEYS.MESSAGE)?.write;
  }
  if (orgData) {
    return getPermission(orgData, PERMISSION_KEYS.MESSAGE)?.write;
  }
  return false;
};

/**
 * Permission check
 */

// return undefined if permissions is undefined (loading)
const canPerformAction = (permissions, key, action) =>
  !!permissions?.[key]?.[action];

export const canReadDevices = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.DEVICES,
    PERMISSION_ACTIONS.READ
  );

export const canReadDeviceExtra = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.DEVICE_EXTRA,
    PERMISSION_ACTIONS.READ
  );

export const canReadProjectExtra = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.PROJECT_EXTRA,
    PERMISSION_ACTIONS.READ
  );

export const canReadProject = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.PROJECT,
    PERMISSION_ACTIONS.READ
  );

export const canReadUsers = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.USERS,
    PERMISSION_ACTIONS.READ
  );

export const canReadUserPermissions = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.USER_PERMISSIONS,
    PERMISSION_ACTIONS.READ
  );

export const canReadCode = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.CODE,
    PERMISSION_ACTIONS.READ
  );

export const canReadContent = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.CONTENT,
    PERMISSION_ACTIONS.READ
  );

export const canReadProjectApprovals = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.PROJECT_APPROVALS,
    PERMISSION_ACTIONS.READ
  );

export const canReadShowroom = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.PROJECT_EXTRA,
    PERMISSION_ACTIONS.READ
  );

export const canReadProjectMedia = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.PROJECT_MEDIA,
    PERMISSION_ACTIONS.READ
  );

export const canReadAnalytics = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.ANALYTICS,
    PERMISSION_ACTIONS.READ
  );
