import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useMobileLayout } from "hooks/uiHooks";
import { areDictsEqual } from "utils/generalUtils";
import { pinProject } from "services/UserService";
import {
  useProjectInfo,
  useUsersInProject,
  useUserProjectPermissions,
} from "services/ProjectService";
import { DefaultStrings, ProjectStrings } from "strings";
import {
  useOrganisationInfo,
  useUserOrganisationPermissions,
} from "services/OrganisationService";
import { Pinned, Content } from "./components";
import { useStyles } from "./style";
import { OrganisationIcon } from "assets/icons";
import OverflowTypography from "ui/OverflowTypography";
import UserAvatar from "ui/UserAvatar";
import LinearSpinner from "ui/LinearSpinner";
import { getProjectOverviewRoute } from "route";

/*
 sample config
 */
// const config = {
//   showBrand: true,
//   showDescription: true,
//   showParticipants: true,
//   showPin: true,
//   disableShadow: false,
//   loading: false,
//   onClick: (projectId) => {}
// };

const areCardsEqual = (props1, props2) =>
  props1.projectId === props2.projectId &&
  areDictsEqual(props1.config, props2.config);

const ProjectCard = memo(
  ({
    userId,
    projectId,
    config: {
      info,
      showBrand,
      showDescription,
      showParticipants,
      showPin,
      showOrgName,
      disableShadow,
      onClick,
      clickToOpen,
      loading,
      hoverProjectId,
      onPinProject,
    },
  }) => {
    const mobile = useMobileLayout();
    const avatarTeamSize = mobile ? 5 : 4;
    const project =
      useProjectInfo({ projectId: !info && projectId, userId }) || info;
    // permissions control
    const { canReadUsers, isSuperAdmin } = useUserProjectPermissions({
      userId,
      projectId,
    });
    const usersInProj = useUsersInProject(canReadUsers && projectId);
    const { canReadOrganisation } = useUserOrganisationPermissions({
      userId,
      organisationId: project?.organisationId,
    });
    const orgInfo = useOrganisationInfo(
      canReadOrganisation && project?.organisationId
    );
    const clickable = onClick || clickToOpen;
    const classes = useStyles({ disableShadow, clickable });

    const participants = useMemo(() => {
      if (!usersInProj)
        return <span className={classes.emptyParticipants}></span>;
      const usersCount = usersInProj?.length - 1;
      if (usersCount > 5) {
        return ` and ${usersCount - 5} other${usersCount - 5 >= 2 ? "s" : ""}`;
      }
      return null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersInProj]);

    const name = useMemo(
      () => (project && project.name ? project.name : DefaultStrings.APP_NAME),
      [project]
    );

    const brand = useMemo(
      () => (project && project.brand ? project.brand : ""),
      [project]
    );

    const desc = useMemo(
      () => (project && project.desc ? project.desc : ""),
      [project]
    );
    const pinned = useMemo(() => project && project.pinned, [project]);

    if (!project) return null;

    const CardComponent = clickable ? CardActionArea : CardContent;

    const onClickHandle = () => {
      if (onClick) onClick(projectId, !!project?.joined);
    };

    const onPinnedHandler = async (event) => {
      event.stopPropagation();
      event.preventDefault();
      await pinProject({ projectId, pin: !pinned });
      onPinProject();
    };

    const renderContent = (
      <>
        {loading && <LinearSpinner />}
        {project && project.imageURL && (
          <CardMedia className={classes.media} image={project.imageURL} />
        )}
        {
          <Content className={classes.name} variant="h6" color="textPrimary">
            {name}
          </Content>
        }
        {showBrand && <Content>{brand}</Content>}
        {showParticipants && (
          <div className={classes.avatarsWrapper}>
            {usersInProj
              ?.sort((a, _b) => (a.usersId === userId ? -1 : 0))
              .slice(0, 6)
              .map((user, idx) => (
                <Box
                  style={{
                    marginLeft: idx === 0 ? "unset" : "-6px",
                    zIndex: idx,
                  }}
                  key={user.usersId}
                >
                  <UserAvatar userId={user.usersId} size={avatarTeamSize} />
                </Box>
              ))}
            <Typography
              className={classes.participants}
              color="textSecondary"
              variant="body2"
            >
              {participants}
            </Typography>
          </div>
        )}
        {showDescription && (
          <Content className={classes.desc} noOverflow>
            {desc}
          </Content>
        )}
        {showOrgName && (
          <div
            className={classes.orgNameWrapper}
            style={{
              visibility:
                // hide for users in org
                orgInfo ||
                // hide for guests
                (!orgInfo && !canReadOrganisation && project?.organisationId)
                  ? "hidden"
                  : "visible",
            }}
          >
            <div className={classes.orgImageWrapper}>
              <OrganisationIcon />
            </div>
            <OverflowTypography
              className={classes.orgName}
              variant="body2"
              color="textPrimary"
            >
              {ProjectStrings.PROJECT_NOT_ASSIGNED}
            </OverflowTypography>
          </div>
        )}
      </>
    );

    const cardContent = (
      <Card key={`ProjectCard-${projectId}`} className={classes.card}>
        {showPin && (mobile || projectId === hoverProjectId || pinned) && (
          <Pinned pinned={pinned} onClick={onPinnedHandler} />
        )}
        <CardComponent
          className={classes.action}
          onClick={loading ? null : onClickHandle}
        >
          {renderContent}
        </CardComponent>
      </Card>
    );

    return clickToOpen && (!!project?.joined || isSuperAdmin) ? (
      <Link
        className={classes.cardAnchor}
        to={getProjectOverviewRoute(projectId)}
      >
        {cardContent}
      </Link>
    ) : (
      cardContent
    );
  },
  areCardsEqual
);

export default ProjectCard;
