import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import clsx from "clsx";

import {
  useProjectCodeInfo,
  useProjectInfo,
  useUserInProject,
  useUserAwaitingApproval,
} from "services/ProjectService";
import { previewProject } from "services/ApiService";
import ProjectPreviewDialog from "ui/dialogs/ProjectPreviewDialog";
import { getProjectOverviewRoute } from "route";
import { DefaultStrings, ProjectStrings } from "strings";
import {
  GUEST_ACCESS,
  PROJECT_ACCESS,
  useUserOrganisationPermissions,
  PROJECT_JOIN_APPROVAL,
} from "services/OrganisationService";
import { useStyles } from "./style";
import { ORGANISATION_PERMISSIONS } from "utils/permissionUtils";

/**
 * sample config
 */
// const config = {
//   projectId: "projectId",
//   code: "code",
//   onClose: () => {},
//   onConfirm: () => {},
// }

// a change of code or projectId will trigger open
const PreviewDialog = ({
  config: {
    code,
    projectId: projectIdToJoin,
    userId,
    approvalRequested,
    progress,
    hideViewBtn,
    onClose,
    onConfirm,
  },
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const projectCode = useProjectCodeInfo(code);

  const projectId = useMemo(() => {
    if (projectCode) {
      return projectCode?.projectId;
    }
    if (projectIdToJoin) return projectIdToJoin;
    return null;
  }, [projectCode, projectIdToJoin]);

  const joinedProject = useUserInProject({
    projectId,
    checkIfDeleted: false,
  });
  const awaitingApproval = useUserAwaitingApproval({ userId, projectId });
  const [info, setInfo] = useState();
  const mounted = useRef(false);

  const projectInfo = useProjectInfo({
    userId,
    projectId:
      (joinedProject && projectId) || projectIdToJoin ? projectId : null,
  });

  const organisationId = info?.organisationId || projectInfo?.organisationId;

  const { userPermissions, isUserAdmin } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });

  const isProjectAccessPrivate = useMemo(() => {
    if (isUserAdmin) return false;

    return (
      (projectInfo?.projectAccess || info?.projectAccess) ===
      PROJECT_ACCESS.PRIVATE
    );
  }, [info, isUserAdmin, projectInfo]);

  const isGuestAccessDenied = useMemo(
    () =>
      (projectCode?.guestAccess || info?.guestAccess) === GUEST_ACCESS.DISABLED,
    [info, projectCode]
  );

  const isApprovalRequired = useMemo(() => {
    if (isUserAdmin) return false;

    return (
      (awaitingApproval?.joinApproval ||
        projectInfo?.joinApproval ||
        info?.joinApproval ||
        projectCode?.joinApproval) === PROJECT_JOIN_APPROVAL.REQUIRED
    );
  }, [awaitingApproval, info, isUserAdmin, projectCode, projectInfo]);

  const loading = useMemo(() => {
    if (progress || (code && info) || projectInfo) return false;
    return true;
  }, [code, info, progress, projectInfo]);

  const onCloseInternal = useCallback(() => {
    setInfo(null);
    if (onClose) onClose();
  }, [onClose]);

  useEffect(() => {
    if (projectCode === null && code !== null) {
      onCloseInternal();
      enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_ERROR, {
        variant: "error",
      });
    }
  }, [code, enqueueSnackbar, onCloseInternal, projectCode]);

  // close dialog and redirect to existing project
  useEffect(() => {
    if (joinedProject && projectInfo?.projectId) {
      if (code) {
        enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_JOINED_ALREADY, {
          variant: "info",
        });
      }
      history.push(getProjectOverviewRoute(projectInfo.projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedProject, projectInfo, code]);

  const getProjectPreview = useCallback(async () => {
    try {
      const result = await previewProject({ projectId, code });
      // only when result.success is true means the result is valid
      if (result.success !== true) {
        throw new Error(result.errors);
      } else {
        if (mounted.current) {
          setInfo({ ...result, projectId });
        }
      }
    } catch (err) {
      console.warn(err);
      // invalid
      enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_ERROR, {
        variant: "error",
      });
      if (mounted.current) {
        onCloseInternal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, projectId]);

  // set info with new project
  useEffect(() => {
    mounted.current = true;

    if (projectId && code && joinedProject === false) {
      getProjectPreview();
    }

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, joinedProject, projectId, projectInfo]);

  if (
    (!code && !projectIdToJoin) ||
    (projectId === null && !projectInfo) ||
    (code && info === null) ||
    joinedProject
  )
    return null;

  const onConfirmHandle = () => {
    if (onConfirm) onConfirm(projectId, false);
  };

  const onViewHandle = () => {
    if (onConfirm) onConfirm(projectId, true);
  };

  const config = {
    info: projectInfo || info,
    onClose: onCloseInternal,
    loading,
    progress,
  };

  const renderContent = () => {
    if (isProjectAccessPrivate && !code) {
      return (
        <>
          <div className={clsx(classes.text, classes.item)}>
            {ProjectStrings.ADD_PROJECT_JOIN_PRIVATE}
          </div>
          <div className={classes.item}>
            <Button variant="contained" onClick={onCloseInternal} fullWidth>
              {DefaultStrings.BUTTON_CANCEL}
            </Button>
          </div>
        </>
      );
    }
    if (
      isGuestAccessDenied &&
      (userPermissions === null ||
        userPermissions?.permissionsKey === ORGANISATION_PERMISSIONS.GUEST)
    ) {
      return (
        <>
          <div className={clsx(classes.text, classes.item)}>
            {ProjectStrings.ADD_PROJECT_JOIN_GUEST_DENIED}
          </div>
          <div className={classes.item}>
            <Button variant="contained" onClick={onCloseInternal} fullWidth>
              {DefaultStrings.BUTTON_CANCEL}
            </Button>
          </div>
        </>
      );
    }

    if (isApprovalRequired && awaitingApproval) {
      return (
        <>
          <div className={clsx(classes.text, classes.item)}>
            {approvalRequested
              ? ProjectStrings.ADD_PROJECT_JOIN_APPROVAL_SUBMITTED
              : ProjectStrings.ADD_PROJECT_JOIN_APPROVAL_WAITING}
          </div>
          <div className={classes.item}>
            <Button
              variant="contained"
              color="primary"
              onClick={onCloseInternal}
              fullWidth
            >
              {DefaultStrings.BUTTON_OK}
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={classes.item}>
          <Button variant="contained" onClick={onCloseInternal} fullWidth>
            {DefaultStrings.BUTTON_CANCEL}
          </Button>
        </div>

        <div className={clsx(classes.item, classes.whiteTitle)}>
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirmHandle}
            fullWidth
          >
            {DefaultStrings.BUTTON_JOIN}
          </Button>
        </div>
        {!hideViewBtn && isUserAdmin && (
          <div className={classes.item}>
            <Button variant="contained" onClick={onViewHandle} fullWidth>
              {DefaultStrings.BUTTON_VIEW}
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <ProjectPreviewDialog config={config}>
      <div style={{ width: "100%", maxWidth: "320px", position: "relative" }}>
        <div className={classes.itemsWrapper}>{renderContent()}</div>
      </div>
    </ProjectPreviewDialog>
  );
};

export default PreviewDialog;
