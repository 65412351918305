import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { OrganisationCard } from "ui/cards/organisationCard/OrganisationCard";
import { useStyles } from "./style";
import {
  useUserOrganisationPermissions,
  useGetOrganisationStats,
} from "services/OrganisationService";
import OrganisationDeviceStatCard from "ui/cards/deviceStatCard/OrganisationDeviceStatCard";
import OrganisationSmartTagsStatCard from "ui/cards/deviceStatCard/OrganisationSmartTagsStatCard";
import TeamStatCard from "ui/cards/teamStatCard/TeamStatCard";
import ProjectsStatCard from "ui/cards/projectsStatCard/ProjectsStatCard";
import GuestsStatCard from "ui/cards/guestsStatCard/GuestsStatCard";
import { useBreakpoints } from "services/UiService";

const Dashboard = ({ userId, loading, onUpdate }) => {
  const { organisationId } = useParams();
  const mounted = useRef(false);

  const { isLargeDesktop, isTablet, isMobile } = useBreakpoints();

  const classes = useStyles({ isMobile, isTablet, isLargeDesktop });
  useGetOrganisationStats(mounted.current && organisationId);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // permissions control
  const {
    canReadOrganisation,
    canWriteOrganisation,
    canReadDevices,
    canReadUsers,
    canReadProjects,
  } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.infoContainer}>
          <OrganisationCard
            organisationId={organisationId}
            loading={loading}
            onClick={canWriteOrganisation && onUpdate}
          />
        </div>
        <div className={classes.statsWrapper}>
          <div className={classes.cardWrapper}>
            <OrganisationDeviceStatCard
              organisationId={organisationId}
              canRead={canReadDevices}
            />
          </div>
          <div className={classes.cardWrapper}>
            <OrganisationSmartTagsStatCard
              organisationId={organisationId}
              canRead={canReadDevices}
            />
          </div>
          <div className={classes.cardWrapper}>
            <TeamStatCard
              organisationId={organisationId}
              canRead={canReadUsers}
            />
          </div>
          <div className={classes.cardWrapper}>
            <GuestsStatCard
              organisationId={organisationId}
              canRead={canReadOrganisation}
            />
          </div>
          <div className={classes.cardWrapper} style={{ flexBasis: "60%" }}>
            <ProjectsStatCard
              organisationId={organisationId}
              canRead={canReadProjects}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
