import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

import {
  getDocument,
  hasDocument,
  useDocument,
  useSubCollectionDocument,
  useSelectorWithCollection,
  useCollection,
  useSelectorWithPath,
  subscribeCollectionToStore,
  unsubscribeCollection,
  getCollectionNewDocId,
  queryCollection,
  useCollectionNoStore,
} from "./FirestoreService";
import {
  useCurrentUserId,
  useProjectPinned,
  useSystemAdmin,
} from "./UserService";
import {
  useOrganisationInfo,
  useUserOrganisationPermissions,
} from "services/OrganisationService";
import { usePermissionsProject } from "./PermissionService";
import {
  uploadProjectImage,
  uploadFile,
  deletePath,
  isPathExist,
  getFileContent,
} from "./StorageService";
import { getLinkUrl } from "./MediaService";
import {
  updateProjectInfo,
  precreateProject,
  createProject as apiCreateProject,
  refreshDevicesOnlineStates,
  updateProjectQRSetup,
} from "./ApiService";
import * as DeviceService from "./DeviceService";
import { useDeviceLastDeployEnd } from "../hooks/deviceHooks";
import { useUserAdmin } from "services/OrganisationService";

import { isUndefined } from "../utils/generalUtils";
import { isLoading } from "../utils/uiUtils";
import { getDateString, dayInMs } from "../utils/localeUtils";
import firebaseConfig from "../configs/firebaseConfig";
import * as Permissions from "utils/permissionUtils";
import { teamContainsFilter } from "utils/teamUtils";

const PROJECT_LEGACY_ID = "__PROJECT_LEGACY_ID__";

const COLLECTIONS = {
  PROJECT: "projects",
  PROJECT_ANALYTICS: "projects_and_analytics",
  PROJECT_APPS: "apps",
  PROJECT_DEFAULT: "project_defaults",
  PROJECT_DEVICES: "projects_and_devices",
  PROJECT_INFO: "projects_and_info",
  PROJECT_CODES: "projectcodes",
  PROJECT_MEDIA_LINKS: "media_links",
  PROJECT_PUBLIC_INFO: "public_info",
  PROJECT_DEFAULT_TAGS: "default_tags",

  USER_PROJECT: "users_and_projects",
  USER_PROJECT_APPROVALS: "users_and_projects_approvals",

  USERS_ORGANISATIONS: "users_and_organisations",

  COUNTRIES: "countries",
  RETAILERS: "retailers",

  // v2
  INSTALL_REQUESTS: "install_requests",
  USER: "users",
};

const DOCUMENTS = {
  EXTRA_INFO: "extraInfo",
  MEDIA_INFO: "mediaInfo",
  QR_SETUP: "qrSetup",

  PROJECT_SIZE: "common_project_sizes",

  DEFAULT_DEVICE_PROJECT: "defaultDeviceProject",
};

const KEYS = {
  PROJECT_ID: "projectId",
  PROJECT_CREATION_DATE: "creation_date",
  PROJECT_CREATOR: "creator",
  PROJECT_CREATOR_ID: "creatorId",
  PROJECT_MODIFIED_DATE: "modified_date",
  PROJECT_DELETED: "deleted",
  PROJECT_NAME: "name",
  PROJECT_BRAND: "brand",
  PROJECT_DESC: "desc",
  PROJECT_IMAGE_URL: "imageURL",
  PROJECT_APPS: "pkgs",
  PROJECT_TIMESTAMP: "timestamp",
  PROJECT_ANALYTICS: "analytics",
  PROJECT_SIZE: "projectSizeKey",
  PROJECT_SIZE_DEFAULT: "default",
  PROJECT_MEDIA_FOLDER: "mediaFolder",
  PROJECT_MEDIA_LAST_UPDATED: "mediaLastUpdated",
  PROJECT_HARDWARE: "hardware",
  PROJECT_TAGS: "tags",
  PROJECT_NOTE: "note",
  PROJECT_STATUS: "status",
  PROJECT_UPLOADER: "uploader",
  PROJECT_JOIN_CODE: "joinCode",
  PROJECT_MEDIA_DRAFT_PUBLISHED: "published",
  PROJECT_MEDIA_DRAFT_APPROVALS: "approvals",
  PROJECT_MEDIA_DRAFT_VERSION: "mediaDraftVersion",
  PROJECT_MEDIA_DRAFT_NAME: "name",
  PROJECT_MEDIA_DRAFT_CREATED_AT: "createdAt",

  PROJECT_CODE: "code",
  PROJECT_CODE_CREATION_DATE: "creationDate",
  PROJECT_CODE_IMMUTABLE: "immutable",
  PROJECT_CODE_CREATOR_ID: "creatorId",
  PROJECT_CODE_NAME: "name",
  PROJECT_CODE_DEFAULT_ROLE: "defaultRole",
  PROJECT_CODE_DEFAULT_ROLE_NAME: "defaultRoleName",
  PROJECT_CODE_DELETED: "deleted",

  PROJECT_TRACKING: "tracking",

  INSTALL_REQUEST_CREATED_AT: "createdAt",
  INSTALL_CHECK_COMPLIANCE_ONLY: "checkComplianceOnly",

  DEVICE_ID: "deviceId",

  USER_ID: "usersId",
  USER_ACCOUNT_ID: "accountId",
  USER_PERMISSION_KEY: "permissionsKey",

  MEDIA_LINK_CREATED_DATE: "created",
  MEDIA_LINK_DELETED: "deleted",
  MEDIA_LINK_DELETED_DATE: "deletedAt",

  ORGANISATION_ID: "organisationId",
};

const SUBCOLLECTIONS = {
  APPROVALS: "approvals",
  EXTRA: "extra",
  MEDIA: "media",
  DRAFTS: "drafts",
  USERS_APPROVALS: "users_approvals",
};

export const STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  DEVELOPMENT: "Development",
};

const STORAGE_FOLDERS = {
  MEDIA: "media",
  PROJECT: "project",
  DOWNLOAD: "download",
  DRAFTS: "drafts",
};

const FILES = {
  PIN_NUMBER: "pinNumber.js",
};

const DELIMITER = "::";

const PIN_NUMBER_PATTERN = /window.(\w+)\s*=\s*['"](\d+)['"]/g;

/**
 * Project Media
 */

const getProjectDraftPath = async (projectId, draftId) =>
  `${STORAGE_FOLDERS.MEDIA}/${projectId}/${
    draftId ? STORAGE_FOLDERS.DRAFTS : STORAGE_FOLDERS.DOWNLOAD
  }/${draftId || (await getProjectMediaFolder(projectId))}`;

const getProjectContentPath = (
  projectId,
  mediaFolder = STORAGE_FOLDERS.PROJECT
) => {
  return (
    mediaFolder &&
    `${STORAGE_FOLDERS.MEDIA}/${projectId}/${STORAGE_FOLDERS.DOWNLOAD}/${mediaFolder}`
  );
};

const filterMediaSize = ({ sizes, key, all }) => {
  // not ready
  if (!sizes) return undefined;

  // return all sizes
  if (all) return sizes;

  // return specific key
  if (key && key in sizes) return sizes[key];

  // return default size key
  return Object.keys(sizes).filter((size) => sizes[size].default)[0];
};

// key = specific => dict of one size
// useAll = all sizes => dict of all sizes
// none = get default size key
export const getProjectMediaSize = async ({ key, getAll }) => {
  const collection = COLLECTIONS.PROJECT_DEFAULT;
  const doc = DOCUMENTS.PROJECT_SIZE;
  const sizes = await getDocument({ collection, doc });
  return filterMediaSize({ sizes, key, all: getAll });
};

// hook version
export const useProjectMediaSize = ({ key, useAll }) => {
  const storeAs = DOCUMENTS.PROJECT_SIZE;
  const config = {
    collection: COLLECTIONS.PROJECT_DEFAULT,
    doc: DOCUMENTS.PROJECT_SIZE,
    storeAs,
  };
  useFirestoreConnect(config);
  const sizes = useSelectorWithCollection(storeAs);
  return filterMediaSize({ sizes, key, all: useAll });
};

export const useProjectResolution = (projectId) => {
  const collection = COLLECTIONS.PROJECT_INFO;
  const doc = projectId;
  const subcollection = SUBCOLLECTIONS.MEDIA;
  const subdoc = DOCUMENTS.MEDIA_INFO;
  const data = useSubCollectionDocument({
    collection,
    doc,
    subcollection,
    subdoc,
  });
  return (data && data[KEYS.PROJECT_SIZE]) || "720TabletLandscape";
};

export const useProjectPins = (projectId) => {
  const info = useProjectInfo({ projectId });
  const mediaFolder = info?.mediaFolder;
  const path = getProjectContentPath(projectId, mediaFolder);
  const pathPin = `${path}/${FILES.PIN_NUMBER}`;
  const [pins, setPins] = useState();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    const func = async () => {
      try {
        const pinExist = await isPathExist(pathPin);
        if (!pinExist) setPins(null);
        const content = await getFileContent(pathPin);
        const res = [...content.matchAll(PIN_NUMBER_PATTERN)];
        if (mounted.current)
          setPins(Object.fromEntries(res.map((r) => [r[1], r[2]])));
      } catch (err) {
        setPins(null);
      }
    };
    func();
    return () => {
      mounted.current = false;
    };
  }, [pathPin]);

  return pins;
};

/**
 * Project info/settings
 */

// construct and id from given userId and projectId
const constructUserProjectId = ({ userId, projectId }) =>
  userId && projectId && `${projectId}_${userId}`;

// construct and id from given projectId and deviceId
const constructProjectDeviceId = ({ projectId, deviceId }) =>
  projectId && deviceId && `${projectId}_${deviceId}`;

export const getProjectInfo = (projectId) =>
  getDocument({ collection: COLLECTIONS.PROJECT_INFO, doc: projectId });

const useProjectInfoWithId = (projectId) =>
  useDocument({ collection: COLLECTIONS.PROJECT_INFO, doc: projectId });

export const useProjectCodeInfo = (code) =>
  useDocument({ collection: COLLECTIONS.PROJECT_CODES, doc: code });

// use project id with share code
//
// return undefined = loading
// return null = not exist
export const useProjectIdWithCode = (code) => {
  const info = useProjectCodeInfo(code);
  return info && info.projectId;
};

// use project info with either:
// 1) code = share code, (priority)
// 2) id = project id
// has to provide either one
//
// return undefined = loading
// return null = not exist
export const useProjectInfo = ({ projectId, code, userId }) => {
  const [userProjExists, setUserProjExists] = useState(false);
  // try to get info with code
  const codeInfo = useProjectCodeInfo(code);
  const idFromCode = codeInfo && codeInfo[KEYS.PROJECT_ID];

  // use id from code first, then given id
  const projectInfo = useProjectInfoWithId(idFromCode || projectId);
  const pinned = useProjectPinned(idFromCode || projectId);
  const doc = constructUserProjectId({ projectId, userId });
  const { canReadOrganisation } = useUserOrganisationPermissions({
    userId,
    organisationId: projectInfo?.organisationId,
  });
  const organisation = useOrganisationInfo(
    canReadOrganisation && projectInfo?.organisationId
  );

  useEffect(() => {
    if (doc) {
      hasDocument({
        collection: COLLECTIONS.USER_PROJECT,
        doc,
      }).then((r) => setUserProjExists(r));
    }
  }, [doc, projectId, userId]);

  const userProj = useDocument({
    collection: userProjExists && COLLECTIONS.USER_PROJECT,
    doc,
  });

  if (projectInfo === null || codeInfo === null) return null;

  let projectData = projectInfo;

  if (userProj) {
    projectData = { ...projectData, joined: true };
  }

  if (organisation) {
    projectData = { ...projectData, organisationName: organisation.name };
  }

  if (projectData) {
    projectData = { ...projectData, pinned };
  }

  return projectData;
};

export const useProject = (projectId) =>
  useDocument({ collection: COLLECTIONS.PROJECT, doc: projectId });

export const useProjectApprovals = (projectId) =>
  useCollection({
    collection:
      projectId &&
      `${COLLECTIONS.PROJECT_INFO}/${projectId}/${SUBCOLLECTIONS.APPROVALS}`,
  });

export const useProjectTitle = (projectId) => {
  const info = useProjectInfo({ projectId });
  const brand = info?.[KEYS.PROJECT_BRAND] ?? "";
  const name = info?.[KEYS.PROJECT_NAME] ?? "";
  return `${brand && brand + " - "}${name}`;
};

export const getProjectName = async (projectId) => {
  const info = await getProjectInfo(projectId);
  return info && info[KEYS.PROJECT_NAME];
};

export const useProjectName = (projectId) => {
  const info = useProjectInfo({ projectId });
  return info && info[KEYS.PROJECT_NAME];
};

export const getProjectMediaFolder = async (projectId) => {
  const info = await getProjectInfo(projectId);
  if (!info) return info;
  return info[KEYS.PROJECT_MEDIA_FOLDER] ?? STORAGE_FOLDERS.PROJECT;
};

// get the media url for a logged user, using projectId
// draftId = null => live
export const getProjectMediaUrl = async ({
  projectId,
  draftId,
  appendTimestamp = false,
}) => {
  // if live, we need to find out the mediaFolder
  const mediaFolder = draftId ?? (await getProjectMediaFolder(projectId));
  const downloadFolder = !draftId
    ? STORAGE_FOLDERS.DOWNLOAD
    : STORAGE_FOLDERS.DRAFTS;

  const linkWithParams = new URL(
    `https://storage.googleapis.com/${firebaseConfig.projectId}.appspot.com/media/${projectId}/${downloadFolder}/${mediaFolder}/index.html`
  );
  linkWithParams.searchParams.set("t", Date.now());
  linkWithParams.searchParams.set("origin", window.location.origin);

  return linkWithParams;
};

// file = File object
// path = only the relative path of the file, excluding file name
export const uploadProjectMediaFile = async ({
  projectId,
  draftName,
  file,
  path,
}) => {
  if (!projectId || !draftName || !file) return Promise.reject();
  const contentPath = await getProjectDraftPath(projectId, draftName);
  const fullPath = `${contentPath}${path}/${file.name}`;
  return uploadFile({ file, path: fullPath });
};

export const deleteProjectMediaFiles = async ({ projectId, draftName }) => {
  if (!projectId) return Promise.reject();
  const path = await getProjectDraftPath(projectId, draftName);
  console.debug("deleteProjectMediaFiles", path);
  const deleted = await deletePath(path);
  console.debug("deleteProjectMediaFiles", `${deleted} files/folders deleted`);
};

/**
 * Project extra
 */

const useProjectExtra = (projectId) => {
  const collection = COLLECTIONS.PROJECT_INFO;
  const doc = projectId;
  const subcollection = SUBCOLLECTIONS.EXTRA;
  const subdoc = DOCUMENTS.EXTRA_INFO;
  return useSubCollectionDocument({ collection, doc, subcollection, subdoc });
};

export const useProjectHardware = (projectId) => {
  const extra = useProjectExtra(projectId);
  // undefined = loading
  if (isUndefined(extra)) return extra;
  return extra && KEYS.PROJECT_HARDWARE in extra
    ? extra[KEYS.PROJECT_HARDWARE]
    : [];
};

export const useProjectTags = (projectId) => {
  const extra = useProjectExtra(projectId);
  // undefined = loading
  if (isUndefined(extra)) return extra;
  return extra && KEYS.PROJECT_TAGS in extra ? extra[KEYS.PROJECT_TAGS] : {};
};

export const useProjectNote = (projectId) => {
  const extra = useProjectExtra(projectId);
  // undefined = loading
  if (isUndefined(extra)) return extra;
  return extra && KEYS.PROJECT_NOTE in extra ? extra[KEYS.PROJECT_NOTE] : "";
};

export const useProjectStatus = (projectId) => {
  const extra = useProjectExtra(projectId);
  // undefined = loading
  if (isUndefined(extra)) return extra;
  return extra && KEYS.PROJECT_STATUS in extra
    ? extra[KEYS.PROJECT_STATUS]
    : STATUS.DEVELOPMENT;
};

export const useProjectDevicesOnlineState = (projectId, lastDays = 90) => {
  const [onlineStates, setOnlineStates] = useState({});
  const [deviceIds, setDeviceIds] = useState(null);
  const [refreshed, setRefreshed] = useState(false);
  const deviceIdsStr = deviceIds && deviceIds.sort().join(":");

  // refresh data first
  useEffect(() => {
    if (!projectId) return;
    refreshDevicesOnlineStates(projectId, lastDays, true)
      .then((result) => {
        console.debug("refreshDevicesOnlineStates", result);
      })
      .finally(() => {
        setRefreshed(true);
      });
  }, [projectId, lastDays]);

  // get device ids
  useEffect(() => {
    getProjectDeviceIds(projectId).then((res) => {
      setDeviceIds(res);
    });
  }, [projectId]);

  // get device online states, only when refreshed
  useEffect(() => {
    if (!refreshed || !deviceIdsStr) return;

    const ids = deviceIdsStr.split(":");
    const res = {};
    const unsubs = [];
    ids.forEach(async (id) => {
      if (id in onlineStates) return;
      unsubs.push(
        // subscribe to device extra info
        DeviceService.subscribeDeviceExtraInfo({
          deviceId: id,
          onData: (data) => {
            if (data?.onlineState) {
              // add online state
              setOnlineStates((s) => ({
                ...s,
                [id]: data?.onlineState,
              }));
            } else {
              // remove online state
              setOnlineStates((s) => {
                // needs to return a new object
                const n = Object.assign({}, s);
                delete n[id];
                return n;
              });
            }
          },
          onError: (err) => {
            console.warn(`onlineState(${id})`, err);
          },
        })
      );
    });
    setOnlineStates(res);

    return () => {
      unsubs.forEach((unsub) => {
        unsub();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceIdsStr, refreshed]);

  // prepare days results
  const currentDate = new Date();
  const todayTimestamp = currentDate.setHours(0, 0, 0, 0);
  const timestampFrom = todayTimestamp - lastDays * dayInMs; // from N days ago
  const timestampTo = todayTimestamp; // up to today
  const results = {};
  for (
    var timestampIndex = timestampFrom;
    timestampIndex < timestampTo;
    timestampIndex += dayInMs
  ) {
    results[getDateString(timestampIndex)] = 0;
  }

  // aggregate all device
  if (onlineStates) {
    Object.keys(onlineStates).forEach((deviceId) => {
      if (!onlineStates[deviceId]) return;
      // for each device
      Object.keys(results).forEach((key) => {
        // for each day
        // only if in online state
        if (key in onlineStates[deviceId]) {
          results[key] += onlineStates[deviceId][key] ? 1 : 0;
        }
      });
    });
  }

  return refreshed ? results : undefined;
};

/**
 * Project user management
 */

// get all users in a project => array
export const useProjectAllUsers = (projectId, params) => {
  const storeAsProjectAllUsers = `useProjectAllUsers${DELIMITER}${projectId}`;
  const configs = projectId && {
    collection: COLLECTIONS.USER_PROJECT,
    where: [[KEYS.PROJECT_ID, "==", projectId]],
    storeAs: storeAsProjectAllUsers,
    populates: [
      {
        child: KEYS.USER_ID,
        root: COLLECTIONS.USER,
      },
    ],
  };
  useFirestoreConnect(configs);
  const userAndProjects = useSelectorWithCollection(storeAsProjectAllUsers);
  const users = useSelectorWithCollection(COLLECTIONS.USER);
  const [usersResult, setUsersResult] = useState();

  const orderBy = params && params.orderBy;
  const orderDesc = params && params.orderDesc;
  const startAt = params && params.startAt;
  const limit = params && params.limit;
  const filter = params && params.filter;

  useEffect(() => {
    if (
      (typeof users === "undefined" ||
        typeof userAndProjects === "undefined") &&
      userAndProjects !== null
    )
      return;

    if (userAndProjects === null) {
      setUsersResult([]);
      return;
    }

    // filter null values
    // combine data
    let res = Object.entries(userAndProjects)
      .filter((item) => item[1] !== null)
      .map(([k, id]) => {
        return {
          ...users[id.usersId],
          permissionsKey: id.permissionsKey,
          creation_date: id.creationDate,
          projectId: id.projectId,
        };
      });

    // sort
    if (orderBy && typeof orderDesc !== "undefined") {
      res = res.sort((infoA, infoB) => {
        const idFirstForUsers = orderDesc ? infoB.usersId : infoA.usersId;
        const idSecondForUsers = orderDesc ? infoA.usersId : infoB.usersId;
        const idFirstForUserAndProjects = orderDesc
          ? infoB.projectId + "_" + infoB.usersId
          : infoA.projectId + "_" + infoA.usersId;
        const idSecondForUserAndProjects = orderDesc
          ? infoA.projectId + "_" + infoA.usersId
          : infoB.projectId + "_" + infoB.usersId;

        const valueFirst =
          orderBy === "name"
            ? users[idFirstForUsers] && users[idFirstForUsers][orderBy]
            : userAndProjects[idFirstForUserAndProjects] &&
              userAndProjects[idFirstForUserAndProjects][orderBy];

        const valueSecond =
          orderBy === "name"
            ? users[idSecondForUsers] && users[idSecondForUsers][orderBy]
            : userAndProjects[idSecondForUserAndProjects] &&
              userAndProjects[idSecondForUserAndProjects][orderBy];

        if (typeof valueFirst === "undefined") {
          return typeof valueSecond === "undefined" ? 0 : 1;
        }
        if (typeof valueSecond === "undefined") {
          return typeof valueFirst === "undefined" ? 0 : -1;
        }
        if (typeof valueFirst === "string" && typeof valueSecond === "string")
          return valueSecond.localeCompare(valueFirst);
        else return valueSecond - valueFirst;
      });
    }

    // filter
    if (filter) {
      res = res.filter((user) => teamContainsFilter({ user, filter }));
    }

    // limit
    if (typeof startAt !== "undefined" && limit) {
      res = res.slice(startAt, limit);
    }

    setUsersResult(res);
  }, [
    userAndProjects,
    users,
    orderBy,
    orderDesc,
    startAt,
    limit,
    filter,
    projectId,
  ]);
  // TODO: deep-compare userAndProjects and users

  return usersResult;
};

export const useUsersInProject = (projectId) => {
  const users = useCollection({
    collection: projectId && COLLECTIONS.USER_PROJECT,
    where: [[KEYS.PROJECT_ID, "==", projectId]],
  });

  if (typeof users === "undefined") return;
  if (users === null) return null;

  return Object.values(users);
};

const getProjectUsersApprovalsCollection = (projectId) =>
  `${COLLECTIONS.PROJECT_INFO}/${projectId}/${SUBCOLLECTIONS.USERS_APPROVALS}`;

export const useUsersAwaitingApprovalCount = (projectId) => {
  const collection = projectId && getProjectUsersApprovalsCollection(projectId);
  const docs = useCollectionNoStore({
    collection,
    // we just want the count so simply set document to true
    processData: (id, data) => true,
  });
  if (isUndefined(docs)) return docs;
  return docs ? Object.values(docs).filter((d) => d).length : 0;
};

export const useUserAwaitingApproval = ({ userId, projectId }) =>
  useSubCollectionDocument({
    collection: projectId && userId && COLLECTIONS.PROJECT_INFO,
    doc: projectId,
    subcollection: SUBCOLLECTIONS.USERS_APPROVALS,
    subdoc: userId,
  });

export const isUserInProject = async ({
  userId,
  projectId,
  excludeDeleted = true, // keep the flexibility of being able to include/exclude deleted project.
}) => {
  if (!userId || !projectId) return false;
  const doc = constructUserProjectId({ projectId, userId });
  if (!doc) return false;

  const promises = [
    getProjectInfo(projectId),
    hasDocument({
      collection: COLLECTIONS.USER_PROJECT,
      doc,
    }),
  ];

  const [projectInfo, userInProject] = await Promise.all(promises);
  return !userInProject
    ? false // user not in project = false
    : projectInfo.deleted && excludeDeleted
    ? false // project is deleted and exclude = false
    : true; // otherwise true
};

// use if user is already inside a project
// use current user if userId not provided
//
// return undefined = loading
export const useUserInProject = ({
  userId,
  projectId,
  checkIfDeleted = true, // check if project info deleted
}) => {
  const currentUserId = useCurrentUserId();
  const id = userId || currentUserId;
  const doc = constructUserProjectId({ projectId, userId: id });

  const userAndProject = useDocument({
    collection: projectId && COLLECTIONS.USER_PROJECT,
    doc,
  });

  const projectInfo = useProjectInfo({
    userId,
    projectId: checkIfDeleted ? projectId : null,
  });

  if (typeof projectInfo === "undefined" && checkIfDeleted) return projectInfo;
  if (projectInfo && projectInfo[KEYS.PROJECT_DELETED]) return false;
  if (typeof userAndProject === "undefined") return;
  if (userAndProject === null) return false;

  return true;
};

// use permission of user to a project
export const useUserProjectPermissions = ({ userId, projectId }) => {
  const permissions = usePermissionsProject();
  const isSuperAdmin = useSystemAdmin();
  let organisationId;

  const doc = constructUserProjectId({ projectId, userId });
  const userInProject = useDocument({
    collection: projectId && COLLECTIONS.USER_PROJECT,
    doc,
  });
  const info = useProjectInfoWithId(projectId);
  if (info && KEYS.ORGANISATION_ID in info) {
    organisationId = info[KEYS.ORGANISATION_ID];
  }

  const isUserAdmin = useUserAdmin({ userId, organisationId });
  const [userPermissions, setUserPermissions] = useState();
  const [infoKey, setInfoKey] = useState(null);

  useEffect(() => {
    if (userInProject) {
      const key = userInProject[KEYS.USER_PERMISSION_KEY];

      if (!key) {
        console.warn(`${KEYS.USER_PERMISSION_KEY} key missing for ${doc}`);
        return;
      }

      if (infoKey === key) return;

      setInfoKey(key);
      setUserPermissions(null);
    } else if (
      userInProject === null &&
      typeof userPermissions === "undefined"
    ) {
      setUserPermissions(null);
    }
  }, [doc, userInProject, infoKey, userPermissions]);

  useEffect(() => {
    if (!permissions) return;

    // Admin persmissions for SuperAdmin
    if (isSuperAdmin && !userPermissions) {
      setUserPermissions(
        permissions[Permissions.ORGANISATION_PERMISSIONS.ADMIN]
      );
    }

    if (!infoKey) return;

    if (!(infoKey in permissions)) {
      console.warn(`Unknown ${KEYS.USER_PERMISSION_KEY} key`);
      return;
    }

    if (userPermissions === null) {
      setUserPermissions(permissions[infoKey]);
    }
  }, [info, infoKey, isSuperAdmin, permissions, userId, userPermissions]);

  // project permissions
  const canDeleteProject =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canDeleteProject(userPermissions);
  const canWriteProject =
    isSuperAdmin || isUserAdmin || Permissions.canWriteProject(userPermissions);
  const canReadProject =
    isSuperAdmin || isUserAdmin || Permissions.canReadProject(userPermissions);
  const canReadProjectExtra =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canReadProjectExtra(userPermissions);
  const canWriteProjectExtra =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canWriteProjectExtra(userPermissions);
  const canWriteProjectApprovals =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canWriteProjectApprovals(userPermissions);
  const canReadShowroom =
    isSuperAdmin || isUserAdmin || Permissions.canReadShowroom(userPermissions);
  const canWriteShowroom =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canWriteShowroom(userPermissions);
  const canWriteContent =
    isSuperAdmin || isUserAdmin || Permissions.canWriteContent(userPermissions);

  // device permissions
  const canReadDevices =
    isSuperAdmin || isUserAdmin || Permissions.canReadDevices(userPermissions);
  const canWriteDevices =
    isSuperAdmin || isUserAdmin || Permissions.canWriteDevices(userPermissions);
  const canReadDeviceExtra =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canReadDeviceExtra(userPermissions);
  const canWriteDeviceExtra =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canWriteDeviceExtra(userPermissions);

  const canReadAnalytics =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canReadAnalytics(userPermissions);
  const canWriteAnalytics =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canWriteAnalytics(userPermissions);

  // team permissions
  const canReadCode =
    isSuperAdmin || isUserAdmin || Permissions.canReadCode(userPermissions);
  const canWriteCode =
    isSuperAdmin || isUserAdmin || Permissions.canWriteCode(userPermissions);
  const canReadUsers =
    isSuperAdmin || isUserAdmin || Permissions.canReadUsers(userPermissions);
  const canDeleteUsers =
    isSuperAdmin || isUserAdmin || Permissions.canDeleteUsers(userPermissions);
  const canReadUserPermissions =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canReadUserPermissions(userPermissions);
  const canWriteUserPermissions =
    isSuperAdmin ||
    isUserAdmin ||
    Permissions.canWriteUserPermissions(userPermissions);

  return {
    userPermissions,
    canDeleteProject,
    canReadProject,
    canWriteProject,
    canReadProjectExtra,
    canWriteProjectExtra,
    canWriteProjectApprovals,
    canReadDevices,
    canWriteDevices,
    canReadDeviceExtra,
    canWriteDeviceExtra,
    canReadAnalytics,
    canWriteAnalytics,
    canReadShowroom,
    canWriteShowroom,
    canWriteContent,
    canReadCode,
    canWriteCode,
    canReadUsers,
    canDeleteUsers,
    canReadUserPermissions,
    canWriteUserPermissions,
    isSuperAdmin,
    isUserAdmin,
  };
};

export const useUserProjectsIds = (userId) => {
  const docs = useCollection({
    collection: userId && COLLECTIONS.USER_PROJECT,
    where: [[KEYS.USER_ID, "==", userId]],
  });
  if (!docs) return docs;
  return Object.values(docs)
    .map((d) => d[KEYS.PROJECT_ID])
    .sort();
};

export const useUserProjectsPermissions = (userId) => {
  const docs = useCollection({
    collection: COLLECTIONS.USER_PROJECT,
    where: [[KEYS.USER_ID, "==", userId]],
  });
  if (!docs) return docs;
  return Object.fromEntries(
    Object.values(docs).map((d) => [
      d[KEYS.PROJECT_ID],
      d[KEYS.USER_PERMISSION_KEY],
    ])
  );
};

/**
 * Project management
 */

export const deleteProject = (projectId) => {
  return updateProjectInfo({
    projectId,
    info: { [KEYS.PROJECT_DELETED]: true },
  });
};

export const createProject = async (params) => {
  const { [KEYS.PROJECT_IMAGE_URL]: imageFile, ...restParams } = params;
  const res = await precreateProject(restParams.organisationId);
  const projectId = res.result;

  let imageURL;

  if (imageFile) {
    // get the image download URL
    // convert the File object to the url
    imageURL = await uploadProjectImage({
      file: imageFile,
      projectId,
    });
  }

  return apiCreateProject({ projectId, imageURL, ...restParams });
};

export const updateProject = async ({ projectId, info }) => {
  // 1. upload new image if given
  if (info[KEYS.PROJECT_IMAGE_URL]) {
    const url = await uploadProjectImage({
      file: info[KEYS.PROJECT_IMAGE_URL],
      projectId,
    });

    // update url into project info
    info[KEYS.PROJECT_IMAGE_URL] = url;
  }
  return updateProjectInfo({ projectId, info });
};

export const getProjectQRSetup = async (projectId) => {
  const data = await getDocument({
    collection: `${COLLECTIONS.PROJECT_INFO}/${projectId}/${SUBCOLLECTIONS.MEDIA}`,
    doc: DOCUMENTS.QR_SETUP,
  });

  return data?.qrSetup;
};

export const updateQRSetup = updateProjectQRSetup;

/**
 * Project media links
 */

const linksSortFunction = (orderBy, desc) => (a, b) => {
  const x = desc ? b[orderBy] : a[orderBy];
  const y = desc ? a[orderBy] : b[orderBy];
  if (isUndefined(x)) return isUndefined(y) ? 0 : 1;
  else if (isUndefined(y)) return -1;
  if (typeof x === "string" && typeof x === "string") return x.localeCompare(y);
  else return x - y;
};

// get the media links in a project
// sort by created (desc), can be customized if needed
// return all links for now until we need infinite scrolling for a large number
export const useProjectSharedMediaLinks = ({ projectId, params }) => {
  const links = useCollection({
    collection: `${COLLECTIONS.PROJECT_INFO}/${projectId}/${COLLECTIONS.PROJECT_MEDIA_LINKS}`,
  });
  // not ready or not exist
  if (!links) return links;

  const includeLink = (link) => {
    if (link[KEYS.MEDIA_LINK_DELETED]) return false;
    return (
      !params.filter ||
      link.name.toLowerCase().includes(params.filter.toLowerCase())
    );
  };

  return (
    Object.values(links)
      .filter((link) => !!link && includeLink(link)) // filter removed/deleted links
      // add link url at run-time
      .sort(linksSortFunction(params.sort, params.sortDesc))
      .map((link) => ({
        ...link,
        url: getLinkUrl(link),
      }))
      .slice(0, params.limit)
  );
};

export const useTotalProjectActiveSharedMediaLink = ({ projectId }) => {
  const docs = useCollection({
    collection: `${COLLECTIONS.PROJECT_INFO}/${projectId}/${COLLECTIONS.PROJECT_MEDIA_LINKS}`,
  });
  if (!docs) return docs;
  return Object.values(docs).filter((d) => !d[KEYS.MEDIA_LINK_DELETED]).length;
};

/**
 * Project share code
 */

export const useProjectShareCodes = (projectId) => {
  const collection = projectId && COLLECTIONS.PROJECT_CODES;
  const where = [[KEYS.PROJECT_ID, "==", projectId]];
  const codes = useCollection({ collection, where });

  if (!codes) return codes;
  return Object.values(codes)
    .filter((c) => !c[KEYS.PROJECT_CODE_DELETED])
    .sort(
      (a, b) =>
        b[KEYS.PROJECT_CODE_CREATION_DATE] - a[KEYS.PROJECT_CODE_CREATION_DATE]
    )
    .map((i) => i[KEYS.PROJECT_CODE]);
};

export const getProjectShareCodeInfo = (code) =>
  getDocument({
    collection: COLLECTIONS.PROJECT_CODES,
    doc: code,
  });

export const useProjectShareCodeInfo = (code) => {
  const info = useDocument({
    collection: COLLECTIONS.PROJECT_CODES,
    doc: code,
  });

  const permissions = usePermissionsProject();
  if (!info) return info;
  if (!permissions) return permissions;
  // if code does not have role, then just return it
  if (!(KEYS.PROJECT_CODE_DEFAULT_ROLE in info)) return info;
  // otherwise try to add the role name
  return {
    ...info,
    [KEYS.PROJECT_CODE_DEFAULT_ROLE_NAME]:
      permissions[info[KEYS.PROJECT_CODE_DEFAULT_ROLE]].name,
  };
};

const sortAndFilterMembers = (members) => {
  if (!members) return members;
  return (
    Object.values(members)
      // sort by joined date
      .sort(
        (a, b) => a[KEYS.PROJECT_CREATION_DATE] - b[KEYS.PROJECT_CREATION_DATE]
      )
      // filter minimal keys
      .map((m) => ({
        [KEYS.USER_ID]: m[KEYS.USER_ID],
        [KEYS.USER_PERMISSION_KEY]: m[KEYS.USER_PERMISSION_KEY],
      }))
  );
};

export const useProjectShareCodeMembers = (code) => {
  const info = useProjectShareCodeInfo(code);
  const collection = COLLECTIONS.USER_PROJECT;
  const where = [
    [KEYS.PROJECT_ID, "==", info?.[KEYS.PROJECT_ID]],
    [KEYS.PROJECT_JOIN_CODE, "==", code],
  ];
  const members = useCollection({ collection: info && collection, where });
  return sortAndFilterMembers(members);
};

/**
 * Devices
 */

export const useDeviceInProject = ({ projectId, deviceId }) => {
  const [res, setRes] = useState();
  useEffect(() => {
    if (!projectId || !deviceId) return;
    hasDocument({
      collection: projectId && deviceId && COLLECTIONS.PROJECT_DEVICES,
      doc: constructProjectDeviceId({ projectId, deviceId }),
    }).then((r) => {
      // store result for each project and device otherwise the previous result will first be returned
      setRes({
        ...res,
        [projectId]: {
          ...res?.[projectId],
          [deviceId]: r,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, deviceId]);
  return res?.[projectId]?.[deviceId];
};

// get array of device id in this project, from "project_devices"
// "project_devices" document has minimal keys therefore useCollection to store them all is fine
export const useProjectDevicesId = (projectId) => {
  const dispatch = useDispatch();
  const collection = COLLECTIONS.PROJECT_DEVICES;
  const path = "projects/device_id";
  const info = useSelectorWithPath(path);
  useEffect(() => {
    if (!projectId) return;
    const unsub = subscribeCollectionToStore({
      collection,
      where: [[KEYS.PROJECT_ID, "==", projectId]],
      dispatch,
      path,
      config: {
        ignoreDoc: true,
        ignoreEmpty: false,
        // id is ${projectId}_${deviceId}
        constructPath: ({ id, path, data }) =>
          // use deviceId from data since not all document ID use the format "projectId_deviceId"
          id
            ? `${path}/${projectId}/${data?.deviceId ?? id.split("_")[1]}`
            : path,
        // we just need the document there
        constructData: () => true,
      },
    });
    return () => {
      unsubscribeCollection({
        collection,
        path,
        dispatch,
      });
      unsub();
    };
  }, [projectId, collection, dispatch, path]);
  if (!projectId) return;
  // unsubscribe will set it to null, so next time we want to return undefined as loading
  if (!info || !(projectId in info)) return null;
  if (!info || !(projectId in info)) return null;
  // deleted device may stay as null so we need to filter out
  return Object.entries(info[projectId])
    .filter(([k, v]) => !!v)
    .map(([k, v]) => k);
};

export const getProjectDeviceIds = async (projectId) => {
  const docs = await queryCollection({
    collection: COLLECTIONS.PROJECT_DEVICES,
    where: [[KEYS.PROJECT_ID, "==", projectId]],
  });
  return docs && Object.values(docs).map((d) => d[KEYS.DEVICE_ID]);
};

export const useDefaultTags = () => {
  const defaultTags = useCollection({
    collection: COLLECTIONS.PROJECT_DEFAULT_TAGS,
  });
  return defaultTags && Object.values(defaultTags).filter((t) => !!t.name);
};

export const useCountries = () => {
  const countries = useCollection({ collection: COLLECTIONS.COUNTRIES });
  return countries && Object.values(countries);
};

export const useRetailers = () => {
  const retailers = useCollection({ collection: COLLECTIONS.RETAILERS });
  return retailers && Object.values(retailers);
};

/**
 * drafts
 */

const getProjectDraftsCollection = (projectId) =>
  `${COLLECTIONS.PROJECT_INFO}/${projectId}/${SUBCOLLECTIONS.DRAFTS}`;

export const createProjectDraftId = (projectId) =>
  getCollectionNewDocId(getProjectDraftsCollection(projectId));

export const useProjectDraftPublicInfo = ({ projectId, draftName }) => {
  const collection = `${COLLECTIONS.PROJECT_INFO}/${projectId}/${COLLECTIONS.PROJECT_PUBLIC_INFO}`;
  return useDocument({
    collection: projectId && draftName && collection,
    doc: draftName && draftName,
  });
};

export const useProjectDraftInfo = ({ projectId, draftName }) =>
  useDocument({
    collection: projectId && draftName && getProjectDraftsCollection(projectId),
    doc: projectId && draftName,
  });

// draftName = null => live
// useProjectApprovals exist = read draft
// useProjectApprovals not exist = read old approvals
export const useProjectDraftApprovals = ({ projectId, draftName }) => {
  const projectInfo = useProjectInfo({ projectId });
  const legacy =
    projectInfo && !(KEYS.PROJECT_MEDIA_DRAFT_VERSION in projectInfo);
  const info = useProjectDraftInfo({
    projectId,
    draftName: draftName ?? projectInfo?.[KEYS.PROJECT_MEDIA_DRAFT_VERSION],
  });
  const approvals = useProjectApprovals(legacy && projectId);
  if (!projectInfo) return projectInfo;
  if (legacy && !draftName) {
    return approvals;
  } else {
    return !info ? info : info[KEYS.PROJECT_MEDIA_DRAFT_APPROVALS];
  }
};

export const useProjectDrafts = (projectId) =>
  useCollection({
    collection: projectId && getProjectDraftsCollection(projectId),
    orderBy: KEYS.PROJECT_MEDIA_DRAFT_NAME,
  });

// return [drafts, liveDraft]
export const useProjectDraftsUnpublished = (projectId) => {
  const docs = useProjectDrafts(projectId);
  if (!docs) return docs;
  return (
    Object.keys(docs)
      // have to filter instead of query since key is absent in document
      .filter((id) => !docs[id][KEYS.PROJECT_MEDIA_DRAFT_PUBLISHED])
      .sort(
        (a, b) =>
          docs[b][KEYS.PROJECT_MEDIA_DRAFT_CREATED_AT] -
          docs[a][KEYS.PROJECT_MEDIA_DRAFT_CREATED_AT]
      )
      .map((id) => ({ id, ...docs[id] }))
  );
};

// undefined = loading
// null = no content
// {} = legacy content
// object = draft content
export const useProjectDraftLive = (projectId) => {
  const info = useProjectInfoWithId(projectId);
  const hasContent = info && KEYS.PROJECT_UPLOADER in info;
  // try to get from drafts subcollection
  const live = useCollection({
    collection: projectId && getProjectDraftsCollection(projectId),
    where: [[KEYS.PROJECT_MEDIA_DRAFT_PUBLISHED, "==", true]],
  });
  if (!info) return info;
  if (isLoading(live)) return live;
  if (live) return { id: Object.keys(live)[0], ...Object.values(live)[0] };
  else if (hasContent) return {};
  else return null;
};

// return [liveDraftId, allDrafts]
// liveDraftId = undefined => no live content
// liveDraftId = null => legacy live content
// liveDraftId = string => draft content
// liveDraftId always overriden by PROJECT_LEGACY_ID
export const useProjectDraftsMenu = (projectId) => {
  const live = useProjectDraftLive(projectId);
  const drafts = useProjectDraftsUnpublished(projectId);
  if (isLoading(live) || isLoading(drafts)) return undefined;
  return [
    live ? PROJECT_LEGACY_ID : null,
    [
      ...(live
        ? [
            {
              // TODO: move into strings
              name: live.name ? `${live.name} (Live)` : "Live",
              id: PROJECT_LEGACY_ID,
            },
          ]
        : []),
      ...(drafts ? drafts.map((d) => ({ name: d.name, id: d.id })) : []),
    ],
  ];
};

// check if a draft exists by checking the index.html inside storage folder
export const useProjectDraftExist = ({ projectId, draftName }) => {
  const [res, setRes] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!projectId || loading) return;

    setLoading(true);
    getProjectDraftPath(projectId, draftName).then((path) => {
      isPathExist(path + "/index.html").then((exist) => {
        setRes(exist);
      });
    });
  }, [loading, projectId, draftName]);
  return res;
};

export const useProjectDraftName = ({ projectId, draftName }) => {
  const _draftName = draftName === PROJECT_LEGACY_ID ? null : draftName;
  const info = useProjectDraftInfo({ projectId, draftName: _draftName });
  if (!draftName) return null;
  if (!info) return info;
  const name = info[KEYS.PROJECT_MEDIA_DRAFT_NAME];
  return name === PROJECT_LEGACY_ID ? null : name;
};

export const useProjectAppsAll = (projectId) => {
  const collection = COLLECTIONS.PROJECT;
  const doc = projectId;
  const info = useDocument({ collection, doc });
  return info?.pkgs && Object.fromEntries(info.pkgs.map((p) => [p.pkgName, p]));
};

// use the configured apps in this project, with only pkgName and ver
export const useProjectApps = (projectId) => {
  const apps = useProjectAppsAll(projectId);
  return (
    apps && Object.fromEntries(Object.entries(apps).map(([k, v]) => [k, v.ver]))
  );
};

/**
 * Project tasks
 */

export const useProjectTasks = (projectId) => {
  const collection = COLLECTIONS.PROJECT;
  const doc = projectId;
  const info = useDocument({ collection, doc });
  return info && info.tasks;
};

/**
 * utils
 */

export const getProjectNameFromInfo = (info) => info?.[KEYS.PROJECT_NAME];

export const getProjectBrandFromInfo = (info) => info?.[KEYS.PROJECT_BRAND];

/**
 * tracking
 */

export const useProjectTracking = (projectId) => {
  const info = useProjectInfoWithId(projectId);
  return info?.[KEYS.PROJECT_TRACKING];
};

/**
 * Install request for V2 (deployment)
 */

export const TASK_TYPES = {
  DOWNLOAD_MEDIA_FILE: "TASK_DOWNLOAD_MEDIA_FILE",
  COPY_APP_FILE_TO_EXTERNAL: "TASK_COPY_APP_FILE_TO_EXTERNAL",
  INSTALL_APPLICATION: "TASK_INSTALL_APPLICATION",
  EXECUTE_COMMAND: "TASK_EXECUTE_SHELL_COMMAND",
  DOWNLOAD_CUSTOM_FILE: "TASK_DOWNLOAD_CUSTOM_FILE",
};

export const DEPLOYMENT_STATUSES = {
  NOT_STARTED: "NOT_STARTED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  WORKING: "WORKING",
  PENDING: "PENDING",
};

export const OVERALL_STATUSES = {
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  PENDING: "PENDING",
  WORKING: "WORKING",
  DOWNLOAD_FILES: "DOWNLOAD_FILES",
  COPY_FILES: "COPYING_FILES",
  INSTALL_APPLICATIONS: "INSTALL_APPLICATIONS",
  EXECUTE_COMMANDS: "EXECUTE_COMMANDS",
  UNKNOWN: "UNKNOWN",
};

const getProjectInstsallRequestPath = (projectId) =>
  `${COLLECTIONS.PROJECT}/${projectId}/${COLLECTIONS.INSTALL_REQUESTS}`;

export const useDeviceLastComplianceCheckTimestamp = (projectId, deviceId) => {
  const v1Timestamp =
    DeviceService.useDeviceLastComplianceCheckTimestamp(deviceId);
  const req = useDeviceLastComplianceCheckRequest(projectId, deviceId);
  const isV2 = !!req;
  return isV2 ? req?.createdAt : v1Timestamp;
};

export const useDeviceLastDeployedTimestamp = (projectId, deviceId) => {
  const v1Timestamp = useDeviceLastDeployEnd(deviceId);
  const req = useDeviceLastDeploymentRequest(projectId, deviceId);
  const isV2 = !!req;
  return isV2 ? req?.createdAt : v1Timestamp;
};

// 1) all tasks completed
// 2) timestamp after "projects.modified_date"
// 3) timestamp afer "projects_and_info.mediaLastUpdated"
export const useDeviceCompliant = (projectId, deviceId) => {
  const v1Compliant = DeviceService.useDeviceCompliant(deviceId);
  const req = useDeviceLastDeploymentOrComplianceCheckRequest(
    projectId,
    deviceId
  );
  const isV2 = !!req;
  const info = useProjectInfo({ projectId: req && projectId });
  const proj = useProject(req && projectId);

  // if not v2 then use v1 state
  if (!isV2) return v1Compliant;

  // loading
  if (isUndefined(req) || isUndefined(info) || isUndefined(proj))
    return undefined;

  // safe check
  if (!req.tasks || req.tasks.length === 0) return false;

  // any task not completed = non-compliant
  if (Object.values(req.tasks).some((t) => t.status !== "completed"))
    return false;

  // timestamp before "projects.modified_date" = non-compliant
  const taskLastUpdated = Object.values(req.tasks).sort(
    (a, b) => b.lastUpdated - a.lastUpdated
  )[0].lastUpdated;
  if (taskLastUpdated <= proj?.[KEYS.PROJECT_MODIFIED_DATE]) return false;

  // timestamp before "projects_and_info.mediaLastUpdated" = non-compliant
  if (taskLastUpdated <= info?.[KEYS.PROJECT_MEDIA_LAST_UPDATED]) return false;

  return true;
};

// one task failed = failed
export const useDeviceDeploymentFailed = (projectId, deviceId) => {
  const req = useDeviceLastDeploymentOrComplianceCheckRequest(
    projectId,
    deviceId
  );
  if (!req) return req;

  const expandedTasks = Object.values(req.tasks)
    .map((tasks) =>
      expandTaskItems(tasks).map((t) => ({ ...t, type: tasks.type }))
    )
    .flat();
  // any task failed = failed
  return expandedTasks.some((t) => t.status === "failed");
};

const useDeviceLastInstallRequest = (projectId, where, orderBy) => {
  const docs = useCollection(
    projectId && {
      collection: getProjectInstsallRequestPath(projectId),
      where,
      orderBy,
      limit: 1,
    }
  );
  if (!docs) return docs;
  if (Object.keys(docs).length === 0) return null;
  return Object.values(docs)[0];
};

export const useDeviceLastDeploymentOrComplianceCheckRequest = (
  projectId,
  deviceId
) =>
  useDeviceLastInstallRequest(
    projectId,
    [[`deviceInfo.serial_number`, "==", deviceId]],
    [[KEYS.INSTALL_REQUEST_CREATED_AT, "desc"]]
  );

export const useDeviceLastDeploymentRequest = (projectId, deviceId) =>
  useDeviceLastInstallRequest(
    projectId,
    [
      [KEYS.INSTALL_CHECK_COMPLIANCE_ONLY, "==", false],
      [`deviceInfo.serial_number`, "==", deviceId],
    ],
    [[KEYS.INSTALL_REQUEST_CREATED_AT, "desc"]]
  );

export const useDeviceLastComplianceCheckRequest = (projectId, deviceId) =>
  useDeviceLastInstallRequest(
    projectId,
    [
      [KEYS.INSTALL_CHECK_COMPLIANCE_ONLY, "==", true],
      [`deviceInfo.serial_number`, "==", deviceId],
    ],
    [[KEYS.INSTALL_REQUEST_CREATED_AT, "desc"]]
  );

export const useDeviceV2 = (projectId, deviceId) =>
  useDeviceLastDeploymentOrComplianceCheckRequest(projectId, deviceId);

// TODO: move strings outside service file
const taskToString = (task, type) => {
  switch (type ?? task.type) {
    case TASK_TYPES.DOWNLOAD_MEDIA_FILE:
    case TASK_TYPES.DOWNLOAD_CUSTOM_FILE:
      try {
        const url = new URL(decodeURIComponent(task.url));
        const urlBasePath = task.urlBasePath;
        url.search = "";
        const href = url.href;
        return href.substring(href.indexOf(urlBasePath) + urlBasePath.length);
      } catch (err) {
        console.error(err);
      }
      return "Download unknown file";
    case TASK_TYPES.COPY_APP_FILE_TO_EXTERNAL:
      return `Copy files to "${task.externalPath}"`;
    case TASK_TYPES.INSTALL_APPLICATION:
      return `${task.appName}-${task.ver}`;
    case TASK_TYPES.EXECUTE_COMMAND:
      return task.name;
    default:
      return "Unknown task";
  }
};

const expandTaskItems = (task) => {
  if (!task.items) return [task];
  switch (task.type) {
    // for download files each task need urlBasePath from task root level
    case TASK_TYPES.DOWNLOAD_MEDIA_FILE:
    case TASK_TYPES.DOWNLOAD_CUSTOM_FILE:
      return Object.values(task.items).map((i) => ({
        ...i,
        urlBasePath: task.urlBasePath,
      }));
    // for other types we can just return the items
    case TASK_TYPES.COPY_APP_FILE_TO_EXTERNAL:
    case TASK_TYPES.INSTALL_APPLICATION:
    case TASK_TYPES.EXECUTE_COMMAND:
    default:
      return Object.values(task.items);
  }
};

// get all status of the given type
const getTaskStatusByType = (tasks, type) => {
  const tasksByType = tasks.filter((t) => t.type === type);
  // each task may contain items therefore expand items
  const tasksExpanded = tasksByType.map((t) => expandTaskItems(t)).flat();
  const completed = tasksExpanded.filter((t) => t.status === "completed");
  const failed = tasksExpanded.filter((t) => t.status === "failed");
  const working = tasksExpanded.filter((t) => t.status === "working");
  const pending = tasksExpanded.filter(
    (t) => !t.status || t.status === "pending"
  );
  const lastUpdatedSorted = tasksByType
    .map((t) => t.lastUpdated)
    .filter((t) => !!t)
    .sort((a, b) => b - a);
  const lastUpdated = lastUpdatedSorted.length > 0 && lastUpdatedSorted[0];
  return {
    total: tasksExpanded.length,
    completed,
    failed,
    working,
    pending,
    lastUpdated,
  };
};

// get unique types of given order
const getTaskTypesByOrder = (tasks, order) => [
  ...new Set(tasks.filter((t) => t.order === order).map((t) => t.type)),
];

export const useDeviceDeploymentState = (
  projectId,
  deviceId,
  checkComplianceOnly
) => {
  const reqDeployment = useDeviceLastDeploymentRequest(
    !checkComplianceOnly && projectId, // conditionally skip query
    deviceId
  );
  useDeviceLastComplianceCheckRequest(
    checkComplianceOnly && projectId, // conditionally skip query
    deviceId
  );
  const reqComplianceCheck = useDeviceLastDeploymentOrComplianceCheckRequest(
    projectId,
    deviceId
  );
  const req = reqDeployment || reqComplianceCheck;
  if (isUndefined(req)) return req;

  var status = DEPLOYMENT_STATUSES.NOT_STARTED;
  var timestamp = null;
  const res = { status, timestamp };
  if (!req) return res;

  res.checkComplianceOnly = req.checkComplianceOnly;

  const tasks = Object.values(req.tasks);

  // timestamp
  timestamp = req.createdAt;
  res.timestamp = timestamp;

  // status
  const completed = tasks.every((t) => t.status === "completed");
  if (completed) {
    status = DEPLOYMENT_STATUSES.COMPLETED;
  } else {
    const pending = tasks.some((t) => t.status === "pending");
    if (pending) {
      status = DEPLOYMENT_STATUSES.PENDING;
    } else {
      const failed = tasks.some((t) => t.status === "failed");
      if (failed) status = DEPLOYMENT_STATUSES.FAILED;
      else status = DEPLOYMENT_STATUSES.WORKING;
    }
  }
  res.status = status;

  // get all the unique orders
  const orderKeys = [
    ...new Set(
      tasks.map((t) => t.order).sort((a, b) => parseInt(a) - parseInt(b))
    ),
  ];
  res.types = {};
  orderKeys.forEach((o) => {
    // get types of this order o
    const types = getTaskTypesByOrder(tasks, o);
    types.forEach((type) => {
      // get all status of this type
      const status = getTaskStatusByType(tasks, type);

      // for completed we just care about the count
      res.types[type] = {
        total: status.total,
        completed: status.completed.length,
        lastUpdated: status.lastUpdated,
      };
      // convert other status to string
      if (status.failed.length > 0) {
        res.types[type].failed = status.failed.map((t) =>
          taskToString(t, type)
        );
      }
      if (status.working.length > 0) {
        res.types[type].working = status.working.map((t) =>
          taskToString(t, type)
        );
      }
      if (status.pending.length > 0) {
        res.types[type].pending = status.pending.map((t) =>
          taskToString(t, type)
        );
      }
    });
  });

  return res;
};

// one line overall status
export const useDeviceOverallStatus = (projectId, deviceId) => {
  const req = useDeviceLastDeploymentOrComplianceCheckRequest(
    projectId,
    deviceId
  );
  const compliant = useDeviceCompliant(projectId, deviceId);
  if (!req) return req;

  const checkComplianceOnly = req.checkComplianceOnly;
  const res = { checkComplianceOnly };

  const expandedTasks = Object.values(req.tasks)
    .map((tasks) =>
      expandTaskItems(tasks).map((t) => ({ ...t, type: tasks.type }))
    )
    .flat();
  // all tasks completed = ready
  if (expandedTasks.every((t) => t.status === "completed"))
    return {
      ...res,
      status: compliant ? OVERALL_STATUSES.COMPLETED : OVERALL_STATUSES.PENDING,
    };
  // any task failed = failed
  if (expandedTasks.some((t) => t.status === "failed"))
    return { ...res, status: OVERALL_STATUSES.FAILED };

  // compliance checked
  const pendingTasks = expandedTasks
    .filter((t) => t.status === "pending")
    .sort((a, b) => b.lastUpdated - a.lastUpdated);
  if (pendingTasks.length > 0)
    return { ...res, status: OVERALL_STATUSES.PENDING };

  // working
  const workingTasks = expandedTasks
    .filter((t) => t.status === "working")
    .sort((a, b) => b.lastUpdated - a.lastUpdated);
  if (workingTasks.length === 0)
    return { ...res, status: OVERALL_STATUSES.WORKING };

  const lastType = workingTasks[0].type;
  switch (lastType) {
    case TASK_TYPES.DOWNLOAD_MEDIA_FILE:
    case TASK_TYPES.DOWNLOAD_CUSTOM_FILE:
      return { ...res, status: OVERALL_STATUSES.DOWNLOAD_FILES };
    // for other types we can just return the items
    case TASK_TYPES.COPY_APP_FILE_TO_EXTERNAL:
      return { ...res, status: OVERALL_STATUSES.COPY_FILES };
    case TASK_TYPES.INSTALL_APPLICATION:
      return { ...res, status: OVERALL_STATUSES.INSTALL_APPLICATIONS };
    case TASK_TYPES.EXECUTE_COMMAND:
      return { ...res, status: OVERALL_STATUSES.EXECUTE_COMMANDS };
    default:
      return { ...res, status: OVERALL_STATUSES.UNKNOWN };
  }
};
