import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import { getOrgPermissionName } from "utils/permissionUtils";
import { useStyles } from "./style";
import { SendIcon, DeleteIcon } from "assets/icons";

export function Actions({ permissionsKey, onResend, onDelete, onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box className={classes.userRoleWrapper} onClick={onClick}>
        <Typography className={classes.userRole}>
          {getOrgPermissionName(permissionsKey)}
        </Typography>
      </Box>
      <div>
        <IconButton className={classes.button} onClick={onResend}>
          <SendIcon />
        </IconButton>
        <IconButton className={classes.button} onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}
