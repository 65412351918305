import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";

import {
  useUsersPendingInvitesCount,
  inviteUsers,
  updateUserInvite,
  deleteUsersInvite,
  getUsersPendingInvite,
} from "services/OrganisationService";
import { useConfirm } from "hooks/uiHooks";
import Spinner from "../Spinner";
import { useStyles } from "./style";
import { OrganisationStrings, DefaultStrings } from "strings";
import { InviteCard, UserRoleSelector } from "./components";
import InfiniteScroll from "ui/InfiniteScroll";
import { debounce } from "services/UiService";
import { EmptyData } from "ui/emptyData";
import PullUpPanel from "ui/PullUpPanel";
import { SendIcon, SearchIcon, DeleteIcon } from "assets/icons";

const LIMIT = 10;

const PendingInvitesPanel = ({ open, organisationId, onClose, canWrite }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const confirm = useConfirm();
  const mount = useRef(false);

  const invitesCount = useUsersPendingInvitesCount(organisationId);

  const [showDialog, setShowDialog] = useState(false);
  const [currentInviteCode, setCurrentInviteCode] = useState(null);
  const [codeProgress, setCodeProgress] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [startAfter, setStartAfter] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [invitesCodes, setInvitesCodes] = useState();
  const [totalInvitesCodes, setTotalInvitesCodes] = useState();

  const resetData = () => {
    setStartAfter();
    setIsFetchedAll(false);
    setIsRequestSent(true);
  };

  const getParams = useCallback(
    (params) => ({
      limit: LIMIT,
      ...params,
    }),
    []
  );

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        organisationId,
        searchValue,
        lastKey: startAfter,
        ...params,
      });

      try {
        const res = await getUsersPendingInvite(requestParams);
        const responseData = res.result;
        const { invitesIds, total } = responseData;

        setTotalInvitesCodes(total);

        if (invitesIds.length === 0 || invitesIds.length < LIMIT) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }
        if (params?.lastKey && !isRequestSent) {
          setInvitesCodes([...invitesCodes, ...invitesIds]);
        } else {
          setInvitesCodes(invitesIds);
          setIsRequestSent(false);
        }

        if (invitesIds.length) {
          setStartAfter(invitesIds[invitesIds.length - 1]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [
      getParams,
      invitesCodes,
      isRequestSent,
      organisationId,
      searchValue,
      startAfter,
    ]
  );

  useEffect(() => {
    if (open && organisationId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, organisationId]);

  useEffect(() => {
    mount.current = true;

    if (!open) {
      setInvitesCodes();
      setStartAfter();
      setSearchValue("");
      setIsFetchedAll(false);
      setIsRequestSent(false);
    }
    return () => {
      mount.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, organisationId]);

  useEffect(() => {
    if (
      open &&
      organisationId &&
      typeof totalInvitesCodes !== "undefined" &&
      invitesCount !== totalInvitesCodes
    ) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, organisationId, totalInvitesCodes, invitesCount]);

  const config = {
    onLoadMore: () => {
      if (isFetchedAll || !startAfter) return;
      fetchData({ lastKey: startAfter });
    },
  };

  const onResendHandle = async (userInvite) => {
    setCodeProgress(userInvite.code);
    const newUsersInvites = [
      {
        email: userInvite.email,
        role: userInvite.permissionsKey,
      },
    ];

    try {
      await inviteUsers({ organisationId, invites: newUsersInvites });
      setCodeProgress(null);
      enqueueSnackbar(OrganisationStrings.INVITE_RESEND_SUCCESS, {
        variant: "success",
      });
    } catch (err) {
      console.warn(err);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
    }
  };

  const onDeleteHandle = async (userInvite) => {
    confirm({
      title: OrganisationStrings.INVITES_DELETE_CONFIRM_TITLE,
      message: OrganisationStrings.INVITES_DELETE_CONFIRM_DESC,
      config: {
        icon: <DeleteIcon />,
        btOkColor: "secondary",
        btOkTitle: DefaultStrings.BUTTON_DELETE,
        cbAction: true,
      },
    })
      .then(async ({ onProgress, onClose }) => {
        onProgress(true);

        try {
          await deleteUsersInvite({ organisationId, code: userInvite.code });
          enqueueSnackbar(OrganisationStrings.INVITE_DELETE_SUCCESS, {
            variant: "success",
          });
        } catch (err) {
          console.warn(err);
          enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        } finally {
          onProgress(false);
          onClose();
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const onCloseUserRoleSelector = () => {
    setShowDialog(false);
  };

  const onOkUserRoleSelector = async (userRole) => {
    try {
      setCodeProgress(currentInviteCode);

      await updateUserInvite({
        organisationId,
        code: currentInviteCode,
        role: userRole,
      });
      setCurrentInviteCode(null);
      setCodeProgress(null);
      setShowDialog(false);
      enqueueSnackbar(OrganisationStrings.INVITE_USER_ROLE_UPDATE_SUCCESS, {
        variant: "success",
      });
    } catch (err) {
      console.warn(err);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
    }
  };

  const onOpenRoleEditor = (userInvite) => {
    setCurrentInviteCode(userInvite.code);
    setShowDialog(true);
  };

  const onCloseHandle = () => onClose();

  const onSearchHandle = debounce((value) => {
    setSearchValue(value);
    resetData();
    fetchData({ searchValue: value, lastKey: null });
  }, 500);

  const menuDeleteAll = {
    label: OrganisationStrings.INVITES_DELETE_ALL,
    disabled: !canWrite,
    color: "error",
    onClick: () => {
      if (invitesCodes.length === 0) return;

      confirm({
        title: OrganisationStrings.INVITES_DELETE_ALL_CONFIRM_TITLE,
        message: OrganisationStrings.INVITES_DELETE_ALL_CONFIRM_DESC,
        config: {
          icon: <DeleteIcon />,
          btOkColor: "secondary",
          btOkTitle: DefaultStrings.BUTTON_DELETE,
          cbAction: true,
        },
      })
        .then(async ({ onProgress, onClose }) => {
          onProgress(true);

          try {
            await deleteUsersInvite({ organisationId });
            enqueueSnackbar(OrganisationStrings.INVITE_DELETE_SUCCESS, {
              variant: "success",
            });
          } catch (err) {
            console.warn(err);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          } finally {
            onClose();
            onProgress(false);
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
  };

  const menuResendAll = {
    label: OrganisationStrings.INVITES_RESEND_INVITATIONS,
    disabled: !canWrite,
    onClick: () => {
      if (invitesCodes.length === 0) return;

      confirm({
        title: OrganisationStrings.INVITES_RESEND_INVITATIONS_CONFIRM_TITLE,
        message: OrganisationStrings.INVITES_RESEND_INVITATIONS_CONFIRM_DESC,
        config: {
          icon: <SendIcon />,
          btOkColor: "primary",
          btOkTitle: DefaultStrings.BUTTON_SEND,
          cbAction: true,
        },
      })
        .then(async ({ onProgress, onClose }) => {
          onProgress(true);

          try {
            await deleteUsersInvite({ organisationId });
            enqueueSnackbar(OrganisationStrings.INVITE_DELETE_SUCCESS, {
              variant: "success",
            });
          } catch (err) {
            console.warn(err);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          } finally {
            onClose();
            onProgress(false);
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
  };

  const panelConfig = {
    title: OrganisationStrings.INVITES_PENDING_TITLE,
    overflow: [menuResendAll, menuDeleteAll],
    onClose: onCloseHandle,
    onSearch: onSearchHandle,
  };

  const renderContent = () => {
    if (typeof invitesCodes === "undefined") return <Spinner />;

    if (!invitesCodes.length)
      return (
        <EmptyData
          title={
            searchValue
              ? OrganisationStrings.INVITES_NO_FOR_SEARCH
              : OrganisationStrings.INVITES_NO_INVITES
          }
          icon={searchValue ? <SearchIcon /> : <SendIcon />}
        />
      );

    return (
      <InfiniteScroll config={config} size={invitesCodes.length}>
        <div className={classes.content}>
          {invitesCodes.map((inviteCode) => (
            <InviteCard
              key={inviteCode}
              organisationId={organisationId}
              code={inviteCode}
              progress={codeProgress === inviteCode}
              onOpen={onOpenRoleEditor}
              onResend={onResendHandle}
              onDelete={onDeleteHandle}
            />
          ))}
        </div>
      </InfiniteScroll>
    );
  };

  return (
    <>
      <PullUpPanel open={open} config={panelConfig}>
        {renderContent()}
      </PullUpPanel>
      <UserRoleSelector
        open={showDialog}
        organisationId={organisationId}
        code={currentInviteCode}
        progress={codeProgress === currentInviteCode?.code}
        canWrite={canWrite}
        onClose={onCloseUserRoleSelector}
        onOk={onOkUserRoleSelector}
      />
    </>
  );
};

export default PendingInvitesPanel;
