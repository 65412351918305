import React, { useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  TextField,
} from "@material-ui/core";
import MyDialog from "../../ui/MyDialog";
import ProgressDialog from "../../ui/dialogs/ProgressDialog";
import { makeStyles } from "@material-ui/core/styles";
import QrCodeIcon from "@material-ui/icons/QrCode";
import TabletIcon from "@material-ui/icons/Tablet";
import { useSnackbar } from "notistack";
import { createQRDevice } from "../../services/ApiService";

import MainPage from "../MainPage";
import DeviceList from "ui/deviceList/DeviceListWithPanel";
import RestrictedContent from "ui/RestrictedContent";

import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import SystemUpdateOutlinedIcon from "@material-ui/icons/SystemUpdateOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CloseIcon from "@material-ui/icons/Close";

import { DEVICE_SORT_OPTION } from "utils/deviceUtils";
import { useMobileLayout } from "hooks/uiHooks";
import {
  useUserProjectPermissions,
  useProjectTitle,
} from "services/ProjectService";
import {
  exportProjectDevices,
  useTotalDevicesCount,
  useLiveDevicesCount,
  DEVICE_PLATFORMS,
} from "services/DeviceService";
import { useSystemAdmin } from "services/UserService";

import { ROUTES, getProjectTeamShareTabRoute } from "../../route";
import { DefaultStrings, DeviceStrings } from "strings";
import { debounce } from "services/UiService";
import { useProjectOverviewTabs } from "./";
import { withInProjectCheck } from "hocs";

const configInstructions = [
  {
    icon: <GetAppOutlinedIcon color="primary" />,
    caption: DeviceStrings.INSTRUCTION_DOWNLOAD_CAPTION,
    desc: DeviceStrings.INSTRUCTION_DOWNLOAD_DESC,
    extra: DeviceStrings.INSTRUCTION_DOWNLOAD_EXTRA,
    isExtraLink: true,
  },
  {
    icon: <SystemUpdateOutlinedIcon color="primary" />,
    caption: DeviceStrings.INSTRUCTION_INSTALL_CAPTION,
    desc: DeviceStrings.INSTRUCTION_INSTALL_DESC,
  },
  {
    icon: <AddOutlinedIcon color="primary" />,
    caption: DeviceStrings.INSTRUCTION_ADD_CAPTION,
    desc: DeviceStrings.INSTRUCTION_ADD_DESC,
    extra: DeviceStrings.INSTRUCTION_ADD_EXTRA,
  },
];

const useStyles = makeStyles((theme) => ({
  decommission: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    textAlign: "center",
    position: "relative",
  },
  content: {
    backgroundColor: theme.palette.background.default,
  },
  instruction: {
    padding: theme.spacing(2),
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    margin: theme.spacing(1),
  },
  caption: {
    fontWeight: "bold",
  },
  desc: {
    color: theme.palette.text.secondary,
  },
  extra: {
    marginTop: theme.spacing(1),
    fontWeight: "bold",
  },
  close: {
    position: "absolute",
    right: theme.spacing(2),
    top: "50%",
    transform: `translateY(-50%)`,
  },
}));

const Instruction = ({ config }) => {
  const classes = useStyles();
  const link =
    config.extra && !config.extra.startsWith("http")
      ? `https://${config.extra}`
      : config.extra;
  return (
    <>
      <Divider variant="fullWidth" />
      <div className={classes.instruction}>
        <div className={classes.row}>
          {config.icon && <span className={classes.icon}>{config.icon}</span>}
          {config.caption && (
            <span className={classes.caption}>{config.caption}</span>
          )}
        </div>
        {config.desc && <div className={classes.desc}>{config.desc}</div>}
        {config.extra && (
          <div className={classes.extra}>
            {config.isExtraLink ? (
              <a href={link} target="_blank" rel="noopener noreferrer">
                {config.extra}
              </a>
            ) : (
              config.extra
            )}
          </div>
        )}
      </div>
    </>
  );
};

const QrDeviceCreationDialog = ({ projectId, open, onClose }) => {
  const platform = DEVICE_PLATFORMS.QRID;
  const [number, setNumber] = useState(1);
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const onOk = async () => {
    try {
      setShowProgressDialog(true);
      await createQRDevice({ projectId, platform, number });
      setShowProgressDialog(false);
      enqueueSnackbar(DeviceStrings.DEVICES_CREATED, {
        variant: "success",
        autoHideDuration: 5000,
      });
      onClose();
    } catch (error) {
      console.error(error);
      setShowProgressDialog(false);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  };

  const configDialog = {
    icon: <QrCodeIcon />,
    title: DeviceStrings.CREATE_DEVICE_TITLE,
    onClose,
    onOk,
    disableOk: number < 1,
  };

  return (
    <>
      <MyDialog open={open} config={configDialog}>
        <Typography variant="body2">
          {DeviceStrings.CREATE_DEVICE_DESC}
        </Typography>
        <Box mt={2}>
          <TextField
            className={classes.fullWidth}
            inputProps={{ type: "number" }}
            label={DeviceStrings.DEVICE_NUMBER}
            value={number}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              const newValue = e.target.value;
              if (newValue > -1 && newValue <= 100) {
                setNumber(newValue);
              }
            }}
          />
        </Box>
      </MyDialog>
      <ProgressDialog
        open={showProgressDialog}
        config={{
          title: DeviceStrings.CREATING_DEVICES,
          desc: DeviceStrings.CREATING_DEVICES_PROGRESS,
        }}
      />
    </>
  );
};

const InstructionDialog = ({ projectId, open, onClose }) => {
  const mobile = useMobileLayout();
  const classes = useStyles();
  const history = useHistory();

  const onShareCode = () => {
    history.push(getProjectTeamShareTabRoute(projectId));
  };

  return (
    <Dialog
      fullWidth
      fullScreen={mobile}
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={classes.title} disableTypography>
        <Typography variant="caption">
          {DeviceStrings.INSTRUCTION_TITLE}
        </Typography>
        <IconButton edge="end" onClick={onClose} className={classes.close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={classes.content}>
        {configInstructions.map((instruction, index) => (
          <Instruction key={`instruction-${index}`} config={instruction} />
        ))}
        <Box ml={2} mb={2}>
          <Button onClick={onShareCode}>
            {DeviceStrings.INSTRUCTION_ADD_BUTTON}
          </Button>
        </Box>
      </div>
    </Dialog>
  );
};

// Trello ticket on permissions: https://trello.com/c/ELZ3Ltzv/105-add-permission-control-for-project-devices-page
const ProjectDevicesPage = ({ userId }) => {
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isAdmin = useSystemAdmin();

  // match route for DeviceList
  const matchDeviceSingle = useRouteMatch(ROUTES.PROJECT_DEVICE_SINGLE);
  const matchDeviceTab = useRouteMatch(ROUTES.PROJECT_DEVICE_TAB);
  const deviceId = matchDeviceTab
    ? matchDeviceTab.params.deviceId
    : matchDeviceSingle?.params.deviceId;
  const tab = matchDeviceTab?.params.tab;

  const projectId = params.projectId;
  const title = useProjectTitle(projectId);

  const [sortOption, setSortOption] = useState(DEVICE_SORT_OPTION[0]);
  const [sortDesc, setSortDesc] = useState(true);
  const [searchString, setSearchString] = useState(null);
  const [showLive, setShowLive] = useState(true);
  const [showInstruction, setShowInstruction] = useState(false);
  const [showQrDeviceCreation, setShowQrDeviceCreation] = useState(false);
  const [exporting, setExporting] = useState(false);

  // permissions control
  const {
    canReadDevices,
    canWriteDevices,
    canReadDeviceExtra,
    canWriteDeviceExtra,
  } = useUserProjectPermissions({
    userId,
    projectId,
  });

  const tabs = useProjectOverviewTabs({
    projectId,
    tabKey: "DEVICES",
  });
  const [multipleTrigger, setMultipleTrigger] = useState(false);

  const allDevices = useTotalDevicesCount(canReadDevices && projectId);
  const liveDevices = useLiveDevicesCount(canReadDevices && projectId);
  const noDevice = !allDevices;
  const noLiveDevice = !liveDevices;
  const noDecommissionDevice = allDevices === liveDevices;

  const configSortOptions = DEVICE_SORT_OPTION.map((opt, index) => ({
    label: DeviceStrings[opt],
    onClick: () => {
      if (sortOption === opt) {
        setSortDesc(!sortDesc);
      }
      setSortOption(opt);
    },
    selected: opt === sortOption,
    desc: sortDesc,
  }));

  // limit search update to 500ms
  const onSearch = debounce((value) => {
    if (value && value.length > 1) setSearchString(value);
    else setSearchString(null);
  }, 500);

  const onExport = (isAdmin = false) => {
    setExporting(true);
    enqueueSnackbar(DeviceStrings.EXPORT_DEVICES, {
      variant: "info",
      autoHideDuration: 5000,
    });
    exportProjectDevices(projectId, title, showLive, isAdmin).then(() => {
      setExporting(false);
    });
  };

  const createQrDeviceAction = {
    icon: <QrCodeIcon />,
    name: DeviceStrings.MENU_CREATE_QR_DEVICE,
    onClick: () => {
      setShowQrDeviceCreation(true);
    },
    disabled: !canReadDeviceExtra,
  };

  const createDisplayDeviceAction = {
    icon: <TabletIcon />,
    name: DeviceStrings.MENU_CREATE_DISPLAY_DEVICE,
    onClick: () => {
      setShowInstruction(true);
    },
    disabled: !canReadDeviceExtra,
  };

  const configPage = {
    userId,
    actionButton: {
      actions: [createQrDeviceAction, createDisplayDeviceAction],
    },
    appBar: {
      tabDefault: "DEVICES",
      tabs,
      onSearch: !canReadDevices || noDevice ? null : onSearch,
      sort: !canReadDevices || noDevice ? null : configSortOptions,
      overflow: [
        {
          label: DeviceStrings.MENU_SELECT_MULTI_DEVICES,
          onClick: () => {
            setMultipleTrigger(!multipleTrigger);
          },
        },
        {
          label: showLive
            ? DeviceStrings.MENU_DECOMMISSION_DEVICES
            : DeviceStrings.MENU_LIVE_DEVICES,
          onClick: () => {
            setShowLive(!showLive);
          },
          disabled: !canReadDevices,
        },
        {
          label: exporting
            ? DeviceStrings.MENU_EXPORT_CSV_DOWNLOADING
            : DeviceStrings.MENU_EXPORT_CSV,
          onClick: () => onExport(false),
          disabled:
            !canReadDevices || // no permission
            exporting || // already exporting
            (showLive && noLiveDevice) || // no live device to export
            (!showLive && noDecommissionDevice), // no decommission device to export
        },
      ],
      title,
    },
    // fixedHeight in order to position placeholder centered
    fixedHeight: !canReadDevices || noLiveDevice,
  };

  if (isAdmin) {
    configPage.appBar.overflow.push({
      label: DeviceStrings.MENU_EXPORT_CSV_ADMIN,
      onClick: () => onExport(true),
    });
  }

  const decommissionContent = !showLive && (
    <div className={classes.decommission}>
      <Box m={1}>
        <div>{DeviceStrings.DECOMMISION_DESC}</div>
      </Box>
      <Box m={1}>
        <Button variant="contained" onClick={(e) => setShowLive(true)}>
          {DeviceStrings.LIVE_DEVICES_BUTTON}
        </Button>
      </Box>
    </div>
  );

  const content = (
    <RestrictedContent permitted={canReadDevices}>
      {decommissionContent}
      <DeviceList
        filter={searchString}
        sortOption={sortOption}
        sortDesc={sortDesc}
        projectId={projectId}
        live={showLive}
        permissions={{
          canReadDevices,
          canWriteDevices,
          canReadDeviceExtra,
          canWriteDeviceExtra,
        }}
        default={{
          deviceId,
          tab,
        }}
        multiple={multipleTrigger}
      />
    </RestrictedContent>
  );

  return (
    <MainPage config={configPage}>
      {content}
      <InstructionDialog
        projectId={projectId}
        open={showInstruction}
        onClose={(e) => setShowInstruction(false)}
      />
      <QrDeviceCreationDialog
        projectId={projectId}
        open={showQrDeviceCreation}
        onClose={(e) => setShowQrDeviceCreation(false)}
      />
    </MainPage>
  );
};

export default withInProjectCheck(ProjectDevicesPage);
