import React, { useCallback, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import EditIcon from "@material-ui/icons/Edit";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";

import MainPage from "../MainPage";
import ProjectActivityDrawer from "../../ui/ProjectActivityDrawer";
import EditMediaPanel from "../../ui/EditMediaPanel";
import MyDialog from "../../ui/MyDialog";
import MobileContent from "../../ui/MobileContent";
import FullScreenMedia from "../../ui/FullScreenMedia";
import { MediaContent, MediaFrame } from "../../ui/media";

import { useMobileLayout } from "../../hooks/uiHooks";
import {
  useUserProjectPermissions,
  useProjectDraftApprovals,
  useProjectTitle,
  useProjectDraftExist,
  useProjectDraftName,
} from "../../services/ProjectService";
import { updateUrl } from "../../services/UiService";
import { approveMediaDraft } from "../../services/ApiService";
import { setMyState, useMyState } from "../../services/StateService";

import { ProjectStrings } from "../../strings";
import {
  ROUTES,
  getProjectMediaRoute,
  getProjectMediaEditTabRoute,
  getProjectMediaMessagesRoute,
  getProjectMediaPreviewRoute,
} from "../../route";
import { useProjectOverviewTabs } from "./";
import { withInProjectCheck } from "hocs";

const useStyles = makeStyles((theme) => {
  return {
    main: (fullscreen) => ({
      position: fullscreen ? "static" : "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      width: "100%",
      height: "100%",
    }),
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  };
});

const hasApproved = (userId, approvals) => {
  if (!userId || !approvals) return false;
  return Object.keys(approvals).some((id) => id === userId);
};

// Trello ticket on permissions: https://trello.com/c/NNhYtf2f/104-add-permission-control-for-project-media-page
const ProjectMediaPage = ({ userId }) => {
  const params = useParams();
  const projectId = params.projectId;
  const mobile = useMobileLayout();
  const dispatch = useDispatch();
  const title = useProjectTitle(projectId);

  // preview draft
  const matchPreview = useRouteMatch(ROUTES.PROJECT_MEDIA_PREVIEW);
  const version = useMyState({ path: `${projectId}/media/version` });
  // current state override path param
  const draftId = version?.id ?? matchPreview?.params.draftId;
  const _draftName = useProjectDraftName({ projectId, draftName: draftId });
  const draftName =
    _draftName === null ? ProjectStrings.MEDIA_LIVE : _draftName;

  // set version state from path param
  useEffect(() => {
    if (!draftId || !draftName) return;
    if (version?.id !== draftId && version?.name !== draftName)
      setMyState({
        dispatch,
        path: `${projectId}/media/version`,
        data: {
          id: draftId,
          name: draftName,
        },
      });
  }, [draftId, draftName, dispatch, projectId, version]);

  const mediaExist = useProjectDraftExist({
    projectId,
    draftName: draftId,
  });
  const approvals = useProjectDraftApprovals({
    projectId,
    draftName: draftId,
  });
  const { enqueueSnackbar } = useSnackbar();
  const matchMessages = !!useRouteMatch(ROUTES.PROJECT_MEDIA_MESSAGES);

  // permissions control
  const { canWriteProjectApprovals, canReadShowroom } =
    useUserProjectPermissions({ userId, projectId });

  const tabs = useProjectOverviewTabs({
    projectId,
    tabKey: "MEDIA",
  });

  // edit urls
  const matchEditTab = useRouteMatch(ROUTES.PROJECT_MEDIA_EDIT_TAB);
  const [tabName, setTabName] = useState(matchEditTab?.params.tab);

  const onShowEditPanel = useCallback(
    (show, name) => {
      // update url
      if (show) {
        // set the tab name to be passed to EditMediaPanel
        setTabName(name ?? "content");
        // set url to edit tab
        updateUrl(getProjectMediaEditTabRoute(projectId, name ?? "content"));
      } else {
        updateUrl(
          draftId
            ? // set url to media preview
              getProjectMediaPreviewRoute(projectId, draftId)
            : // set url to media default
              getProjectMediaRoute(projectId)
        );
      }
      setShowEditMediaPanel(show);
    },
    [projectId, draftId]
  );

  // detect media version change
  useEffect(() => {
    console.debug("ProjectMedia", version);

    if (version) onShowEditPanel(false);
  }, [version, onShowEditPanel, projectId]);

  const [showDrawer, setShowDrawer] = useState(matchMessages);
  const [fullscreen, setFullscreen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showEditMediaPanel, setShowEditMediaPanel] = useState(!!matchEditTab);
  const classes = useStyles(fullscreen);

  // can approve if there is media and not already approved and can write to project
  const canApprove =
    mediaExist && !hasApproved(userId, approvals) && canWriteProjectApprovals;

  const onShowDrawer = (show) => {
    setShowDrawer(show);
    const url = show
      ? getProjectMediaMessagesRoute(projectId)
      : getProjectMediaRoute(projectId);
    updateUrl(url);
  };

  const actionEdit = {
    icon: <EditIcon />,
    name: ProjectStrings.ACTION_EDIT,
    onClick: () => {
      onShowEditPanel(true);
    },
  };
  const actionApprove = {
    icon: <ThumbUpAltOutlinedIcon />,
    name: ProjectStrings.ACTION_APPROVE,
    onClick: () => {
      setShowDialog(true);
    },
    disabled: !canApprove,
  };
  const actionShare = {
    icon: <ShareOutlinedIcon />,
    name: ProjectStrings.SHOWROOM_TITLE,
    onClick: () => {
      onShowEditPanel(true, "showroom");
    },
    disabled: !canReadShowroom,
  };

  const configPage = {
    userId,
    actionButton: mobile
      ? {
          actions: [actionEdit], //, actionShare],
        }
      : {
          actions: [actionEdit, actionApprove, actionShare],
        },
    appBar: {
      tabDefault: "MEDIA",
      tabs,
      title,
      extras: [
        {
          icon: <CommentOutlinedIcon />,
          name: "Activity",
          onClick: () => onShowDrawer(!showDrawer),
        },
      ],
    },
    fixedHeight: true,
  };

  const configDialog = {
    onClose: () => setShowDialog(false),
    onOk: () => {
      setShowDialog(false);
      approveMediaDraft({
        projectId,
        draftName: draftId,
        message: ProjectStrings.MEDIA_DRAFT_APPROVE_MSG.replace(
          "{draft}",
          draftName
        ),
      }).then(() => {
        enqueueSnackbar(ProjectStrings.MEDIA_APPROVE_OK, {
          variant: "success",
        });
      });
    },
  };

  const onFullscreen = () => {
    setFullscreen(true);
  };

  const onClose = (e) => setFullscreen(false);

  const onCloseMediaPanel = () => {
    onShowEditPanel(false);
  };

  if (fullscreen)
    return (
      <FullScreenMedia onClose={onClose}>
        <MediaFrame projectId={projectId} draftId={draftId} />
      </FullScreenMedia>
    );

  const drawer = (
    <ProjectActivityDrawer
      projectId={projectId}
      show={showDrawer}
      onClose={(e) => onShowDrawer(false)}
    />
  );

  return (
    <MainPage config={configPage}>
      {mobile ? (
        <>
          <MobileContent
            title={ProjectStrings.MEDIA_PROJECT_CONTENT_NA_TITLE}
            desc={ProjectStrings.MEDIA_PROJECT_CONTENT_NA_DESC}
          />
          {drawer}
        </>
      ) : (
        <div className={classes.main}>
          <MediaContent
            projectId={projectId}
            onFullscreen={onFullscreen}
            fullscreenDisabled={!mediaExist}
            draftId={draftId}
            draftName={draftName}
          />
          {drawer}
          <MyDialog open={showDialog} config={configDialog}>
            {ProjectStrings.MEDIA_APPROVE_CONFIRM.replace("{draft}", draftName)}
          </MyDialog>
        </div>
      )}
      <EditMediaPanel
        projectId={projectId}
        userId={userId}
        open={showEditMediaPanel}
        onClose={onCloseMediaPanel}
        tabName={tabName}
      />
    </MainPage>
  );
};

export default withInProjectCheck(ProjectMediaPage);
