import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";

import { OrganisationStrings, ProjectStrings, DefaultStrings } from "strings";
import {
  getOrganisationDashboardRoute,
  getOrganisationTeamRoute,
  getOrganisationProjectsRoute,
  getOrganisationGuestsRoute,
  getOrganisationActivityRoute,
  getOrganisationSettingsRoute,
  ROUTES,
} from "route";
import { useConfirm } from "hooks/uiHooks";
import {
  useUserOrganisationPermissions,
  deleteOrganisation,
  useOrganisationInfo,
  useUserHasConnectedDomain,
  leaveOrganisation,
} from "services/OrganisationService";
import { useCurrentOrganisation } from "services/UserService";
import { TEAM_ORGANISATION_SORT_OPTION } from "utils/teamUtils";

export const PROJECT_SORT_OPTION_FIELD = {
  SORT_OPTION_NAME: "name",
  SORT_OPTION_MOSTRECENT: "modified_date",
  SORT_OPTION_BRAND: "brand",
  SORT_OPTION_DATE: "creation_date",
};

export const PROJECT_SORT_OPTION = Object.keys(PROJECT_SORT_OPTION_FIELD);

const TABS_KEYS = {
  DASHBOARD: "DASHBOARD",
  PROJECTS: "PROJECTS",
  TEAM: "TEAM",
  GUESTS: "GUESTS",
  ACTIVITY: "ACTIVITY",
  SETTINGS: "SETTINGS",
};
// return an array of tabs to be used by MyAppBar
export const useOrganisationTabs = (organisationId, isSuperAdmin) => {
  const history = useHistory();
  if (typeof isSuperAdmin === "undefined") return;

  const tabsConfig = [
    {
      key: TABS_KEYS.DASHBOARD,
      label: OrganisationStrings.TAB_TITLE_DASHBOARD,
      routeFunc: getOrganisationDashboardRoute,
    },

    {
      key: TABS_KEYS.TEAM,
      label: OrganisationStrings.TAB_TITLE_TEAM,
      routeFunc: getOrganisationTeamRoute,
    },
    {
      key: TABS_KEYS.GUESTS,
      label: OrganisationStrings.TAB_TITLE_GUESTS,
      routeFunc: getOrganisationGuestsRoute,
    },
    {
      key: TABS_KEYS.ACTIVITY,
      label: OrganisationStrings.TAB_TITLE_ACTIVITY,
      routeFunc: getOrganisationActivityRoute,
    },
    {
      key: TABS_KEYS.SETTINGS,
      label: OrganisationStrings.TAB_TITLE_SETTINGS,
      routeFunc: getOrganisationSettingsRoute,
    },
  ];

  if (isSuperAdmin) {
    tabsConfig.splice(1, 0, {
      key: TABS_KEYS.PROJECTS,
      label: OrganisationStrings.TAB_TITLE_PROJECTS,
      routeFunc: getOrganisationProjectsRoute,
    });
  }

  return tabsConfig.map((cfg) => ({
    key: cfg.key,
    label: cfg.label,
    onClick: () => history.push(cfg.routeFunc(organisationId)),
  }));
};

export const usePageConfig = ({
  path,
  userId,
  sortDesc,
  sortValue,
  onSearch,
  onSort,
  onInviteOpen,
}) => {
  const { organisationId } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const selectedOrganisationId = useCurrentOrganisation();
  const orgInfo = useOrganisationInfo(organisationId);
  const confirm = useConfirm();
  // permissions control
  const {
    canReadUsers,
    canReadProjects,
    canDeleteOrganisation,
    canWriteOrganisation,
    isSuperAdmin,
    isUserAdmin,
  } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });
  const userHasConnectedDomains = useUserHasConnectedDomain(organisationId);
  const organisationTabs = useOrganisationTabs(organisationId, isSuperAdmin);

  const configSortOptions = useMemo(
    () =>
      TEAM_ORGANISATION_SORT_OPTION.map((opt) => ({
        label: OrganisationStrings[opt],
        desc: sortDesc,
        selected: opt === sortValue,
        onClick: () => onSort(opt),
      })),
    [onSort, sortDesc, sortValue]
  );

  const projectSortOptions = useMemo(
    () =>
      PROJECT_SORT_OPTION.map((opt) => ({
        label: ProjectStrings[opt],
        desc: sortDesc,
        selected: opt === sortValue,
        onClick: () => onSort(opt),
      })),
    [onSort, sortDesc, sortValue]
  );

  const [requesting, setRequesting] = useState(false);

  const menuDelete = {
    label: OrganisationStrings.OVERVIEW_MENU_DELETE,
    onClick: async () => {
      await confirm({ message: OrganisationStrings.OVERVIEW_DELETE_CONFIRM });
      setRequesting(true);
      try {
        // delete organisation and show toast
        await deleteOrganisation(organisationId);
        setRequesting(false);
        // redirect to organisations page
        history.push(ROUTES.ADMIN_ORGANISATIONS);
        enqueueSnackbar(OrganisationStrings.OVERVIEW_DELETED, {
          variant: "success",
        });
      } catch (error) {
        setRequesting(false);
        console.error(error);
        enqueueSnackbar(DefaultStrings.ERROR_MSG, {
          variant: "error",
        });
      }
    },
    disabled: !canDeleteOrganisation,
    color: "error",
  };

  const menuInvite = {
    label: OrganisationStrings.OVERVIEW_MENU_INVITE,
    onClick: () => onInviteOpen(),
    disabled: !canWriteOrganisation,
  };

  const menuLeave = {
    label: OrganisationStrings.OVERVIEW_MENU_LEAVE,
    onClick: async () => {
      await confirm({ message: OrganisationStrings.OVERVIEW_LEAVE_CONFIRM });
      setRequesting(true);
      try {
        // leave organisation and show toast
        await leaveOrganisation({ organisationId });
        setRequesting(false);
        enqueueSnackbar(OrganisationStrings.OVERVIEW_LEFT, {
          variant: "success",
        });
      } catch (error) {
        setRequesting(false);
        console.error(error);
        enqueueSnackbar(DefaultStrings.ERROR_MSG, {
          variant: "error",
        });
      }
    },
    disabled:
      (selectedOrganisationId === organisationId &&
        (userHasConnectedDomains === true || isUserAdmin === true)) ||
      (selectedOrganisationId !== organisationId && isSuperAdmin === true),
  };

  switch (path) {
    case ROUTES.ORGANISATION_DASHBOARD: {
      return {
        requesting,
        config: {
          appBar: {
            tabDefault: TABS_KEYS.DASHBOARD,
            overflow: canDeleteOrganisation
              ? [menuLeave, menuDelete]
              : [menuLeave],
            tabs: organisationTabs,
            title: orgInfo?.name,
          },
        },
      };
    }
    case ROUTES.ORGANISATION_PROJECTS: {
      return {
        requesting,
        config: {
          userId,
          appBar: {
            tabDefault: TABS_KEYS.PROJECTS,
            tabs: organisationTabs,
            onSearch:
              canReadProjects &&
              ((value) =>
                value && value.length > 1 ? onSearch(value) : onSearch(null)),
            sort: canReadProjects && projectSortOptions,
            title: orgInfo?.name,
          },
          fixedHeight: true,
        },
      };
    }
    case ROUTES.ORGANISATION_TEAM: {
      return {
        requesting,
        config: {
          userId,
          appBar: {
            tabDefault: TABS_KEYS.TEAM,
            tabs: organisationTabs,
            overflow: [menuInvite],
            onSearch:
              canReadUsers &&
              ((value) =>
                value && value.length > 1 ? onSearch(value) : onSearch(null)),
            sort: canReadUsers && configSortOptions,
            title: orgInfo?.name,
          },
          actionButton: {
            primary: {
              icon: <ShareOutlinedIcon />,
              name: OrganisationStrings.INVITE_BUTTON_INFO,
              onClick: () => {
                onInviteOpen();
              },
              disabled: !canWriteOrganisation,
            },
          },
          fixedHeight: true,
        },
      };
    }
    case ROUTES.ORGANISATION_GUESTS: {
      return {
        requesting,
        config: {
          userId,
          appBar: {
            tabDefault: TABS_KEYS.GUESTS,
            tabs: organisationTabs,
            onSearch:
              canReadUsers &&
              ((value) =>
                value && value.length > 1 ? onSearch(value) : onSearch(null)),
            sort: canReadUsers && configSortOptions,
            title: orgInfo?.name,
          },
          fixedHeight: true,
        },
      };
    }
    case ROUTES.ORGANISATION_ACTIVITY: {
      return {
        requesting,
        config: {
          userId,
          appBar: {
            tabDefault: TABS_KEYS.ACTIVITY,
            tabs: organisationTabs,
            title: orgInfo?.name,
          },
          fixedHeight: true,
        },
      };
    }
    case ROUTES.ORGANISATION_SETTINGS: {
      return {
        requesting,
        config: {
          userId,
          appBar: {
            tabDefault: TABS_KEYS.SETTINGS,
            tabs: organisationTabs,
            title: orgInfo?.name,
          },
          fixedHeight: true,
        },
      };
    }
    default: {
      return {};
    }
  }
};
