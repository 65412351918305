import {
  ORGANISATION_PERMISSIONS,
  ORG_PERMISSION_NAMES,
} from "utils/permissionUtils";

export const USER_ROLE_VALUES = {
  ALL: "all",
  ADMIN: ORGANISATION_PERMISSIONS.ADMIN,
  CREATOR: ORGANISATION_PERMISSIONS.CREATOR,
  VIEWER: ORGANISATION_PERMISSIONS.VIEWER,
};

export const USER_ROLE_OPTIONS = [
  {
    title: "All",
    value: USER_ROLE_VALUES.ALL,
  },
  ...ORG_PERMISSION_NAMES,
];
