import React, { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";

import { getUserInfo } from "services/UserService";
import { getUsersAwaitingApprovalProject } from "services/ApiService";
import { useUsersAwaitingApprovalCount } from "services/ProjectService";
import { joinApproveProject, joinRejectProject } from "services/ApiService";
import { useStyles } from "./style";
import { useMobileLayout, useConfirm } from "hooks/uiHooks";
import { UserRoleSelector, AwaitingApprovalCard } from "./components";
import { TeamCodeStrings, DefaultStrings, ProjectStrings } from "strings";
import Spinner from "ui/Spinner";
import InfiniteScroll from "ui/InfiniteScroll";
import { EmptyData } from "ui/emptyData";
import RestrictedContent from "ui/RestrictedContent";
import { PeopleIcon, CheckIcon, DeleteIcon } from "assets/icons";

const LIMIT = 10;

export const AwaitingApporval = ({ projectId, canEditProject }) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const usersApprovalsCount = useUsersAwaitingApprovalCount(projectId);

  const [updateUserRole, setUpdateUserRole] = useState({});
  const [currentUserId, setCurrentUserId] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [startAfter, setStartAfter] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [totalAwaitingApproval, setTotalAwaitingApproval] = useState();
  const [awaitingApproval, setAwaitingApproval] = useState();

  const resetData = () => {
    setStartAfter();
    setIsFetchedAll(false);
    setIsRequestSent(true);
  };

  const getParams = useCallback(
    (params) => ({
      limit: LIMIT,
      ...params,
    }),
    []
  );

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        projectId,
        lastKey: startAfter,
        ...params,
      });

      try {
        const res = await getUsersAwaitingApprovalProject(requestParams);
        const responseData = res.result;
        const { usersIds, total } = responseData;

        setTotalAwaitingApproval(total);

        if (usersIds.length === 0 || usersIds.length < LIMIT) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }
        if (params?.lastKey && !isRequestSent) {
          setAwaitingApproval([...awaitingApproval, ...usersIds]);
        } else {
          setAwaitingApproval(usersIds);
          setIsRequestSent(false);
        }

        if (usersIds.length) {
          setStartAfter(usersIds[usersIds.length - 1]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [awaitingApproval, getParams, isRequestSent, projectId, startAfter]
  );

  useEffect(() => {
    if (projectId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (
      projectId &&
      typeof totalAwaitingApproval !== "undefined" &&
      usersApprovalsCount !== totalAwaitingApproval
    ) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, totalAwaitingApproval, usersApprovalsCount]);

  const config = {
    onLoadMore: () => {
      if (isFetchedAll || !startAfter) return;
      fetchData({ lastKey: startAfter });
    },
  };

  const onOpenRoleEditor = (user) => {
    if (!(user.usersId in updateUserRole)) {
      setUpdateUserRole({
        ...updateUserRole,
        [user.usersId]: user.permissionsKey,
      });
    }
    setCurrentUserId(user.usersId);
    setShowDialog(true);
  };

  const onAcceptHandle = async (targetUserId) => {
    const targetUserInfo = await getUserInfo(targetUserId);

    const targetUsers = [
      { usersId: targetUserId, permissionsKey: updateUserRole[targetUserId] },
    ];

    confirm({
      title: ProjectStrings.ADD_PROJECT_JOIN_APPROVE_CONFIRM_TITLE.replace(
        "{name}",
        targetUserInfo?.name || targetUserInfo.email
      ),
      message: ProjectStrings.ADD_PROJECT_JOIN_APPROVE_CONFIRM_DESC.replace(
        "{name}",
        targetUserInfo?.name || targetUserInfo.email
      ),
      config: {
        icon: <CheckIcon />,
        btOkColor: "primary",
        btOkTitle: DefaultStrings.BUTTON_APPROVE,
        cbAction: true,
      },
    })
      .then(async ({ onProgress, onClose }) => {
        onProgress(true);
        try {
          await joinApproveProject({
            projectId,
            targetUsers,
          });
          enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_APPROVED, {
            variant: "success",
          });
        } catch (err) {
          console.warn(err);
          enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        } finally {
          setUpdateUserRole({});
          onProgress(true);
          onClose();
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const onRejectHandle = async (targetUserId) => {
    const targetUserInfo = await getUserInfo(targetUserId);
    confirm({
      title: ProjectStrings.ADD_PROJECT_JOIN_REJECT_CONFIRM_TITLE.replace(
        "{name}",
        targetUserInfo?.name || targetUserInfo.email
      ),
      message: ProjectStrings.ADD_PROJECT_JOIN_REJECT_CONFIRM_DESC.replace(
        "{name}",
        targetUserInfo?.name || targetUserInfo.email
      ),
      config: {
        icon: <DeleteIcon />,
        btOkColor: "secondary",
        btOkTitle: DefaultStrings.BUTTON_REJECT,
        cbAction: true,
      },
    })
      .then(async ({ onProgress, onClose }) => {
        onProgress(true);

        try {
          await joinRejectProject({
            projectId,
            targetUsers: [{ usersId: targetUserId }],
          });
          enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_REJECT, {
            variant: "success",
          });
        } catch (err) {
          console.warn(err);
          enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        } finally {
          onClose();
          onProgress(false);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const onCloseUserRoleSelector = () => {
    setShowDialog(false);
  };

  const onOkUserRoleSelector = async (userRole) => {
    setShowDialog(false);
    setUpdateUserRole({
      ...updateUserRole,
      [currentUserId]: userRole,
    });
  };

  const renderContent = () => {
    if (typeof awaitingApproval === "undefined") return <Spinner />;

    if (!awaitingApproval.length)
      return (
        <EmptyData
          title={TeamCodeStrings.TEAM_AWAITING_APPROVAL_NO_USERS}
          icon={<PeopleIcon />}
        />
      );

    return (
      <InfiniteScroll config={config} size={awaitingApproval.length}>
        {awaitingApproval.map((userId) => (
          <AwaitingApprovalCard
            key={userId}
            userId={userId}
            projectId={projectId}
            userRoleToUpdate={updateUserRole[userId]}
            onOpen={onOpenRoleEditor}
            onAccept={onAcceptHandle}
            onReject={onRejectHandle}
          />
        ))}
      </InfiniteScroll>
    );
  };

  return (
    <div className={classes.main}>
      <div className={classes.content}>
        <div className={classes.contentWrapper}>
          <RestrictedContent permitted={canEditProject}>
            {renderContent()}
          </RestrictedContent>
        </div>
        <UserRoleSelector
          open={showDialog}
          projectId={projectId}
          currentUserId={currentUserId}
          onClose={onCloseUserRoleSelector}
          onOk={onOkUserRoleSelector}
          canWrite={canEditProject}
        />
      </div>
    </div>
  );
};
