import React from "react";
import Card from "@material-ui/core/Card";

import { useMobileLayout } from "hooks/uiHooks";
import { useStyles } from "./style";
import { Header } from "../header/Header";
import { Actions } from "../actions/Actions";
import { useUserPendingInvite } from "services/OrganisationService";
import LinearSpinner from "ui/LinearSpinner";

export const InviteCard = ({
  code,
  organisationId,
  progress,
  onOpen,
  onResend,
  onDelete,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const userInvite = useUserPendingInvite({ code, organisationId });

  if (typeof userInvite === "undefined" || userInvite === null) return null;

  const onOpenHandle = () => {
    onOpen(userInvite);
  };
  const onResendHandle = () => {
    onResend(userInvite);
  };
  const onDeleteHandle = () => {
    onDelete(userInvite);
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {progress && <LinearSpinner />}
        <Header
          userId={userInvite.usersId}
          email={userInvite.email}
          onClick={onOpenHandle}
        />
        <Actions
          permissionsKey={userInvite.permissionsKey}
          onClick={onOpenHandle}
          onResend={onResendHandle}
          onDelete={onDeleteHandle}
        />
      </Card>
    </div>
  );
};
