import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import MainPage from "../MainPage";
import ProjectDeviceStatCard from "ui/cards/deviceStatCard/ProjectDeviceStatCard";
import HardwareCard from "ui/cards/HardwareCard";
import PinCard from "ui/cards/PinCard";
import StatusCard from "ui/cards/StatusCard";
import ProjectCard from "ui/cards/projectCard/ProjectCard";
import ProjectDialog from "ui/dialogs/ProjectDialog";
import ProjectNoteCard from "ui/cards/ProjectNoteCard";
import { OrganisationsSelectDialog } from "ui/dialogs/organisationsSelectDialog";

import {
  deleteProject,
  useUserProjectPermissions,
  useProjectTitle,
  useProjectInfo,
  useUserInProject,
} from "services/ProjectService";
import { useTotalDevicesCount } from "services/DeviceService";
import { leaveProject } from "services/ApiService";
import { getProjectCreatorId } from "utils/projectUtils";
import { useConfirm, useMobileLayout } from "hooks/uiHooks";
import { JoinDialog, ProjectTransferDialog } from "ui/dialogs";

import { DefaultStrings, ProjectStrings } from "strings";
import { getProjectsRoute } from "route";
import { useProjectOverviewTabs } from "./";
import { withInProjectCheck } from "hocs";

const useStyles = makeStyles((theme) => ({
  base: (mobile) => ({
    display: "flex",
    flexWrap: mobile ? "wrap" : "nowrap",
    padding: theme.spacing(mobile ? 1 : 2),
  }),
  groupV: {
    flexGrow: 2,
    display: "flex",
    flexDirection: "column",
  },
  groupH: (mobile) => ({
    flexGrow: 1,
    display: "flex",
    flexWrap: mobile ? "wrap" : "nowrap",
  }),
  infoContainer: (mobile) => ({
    minHeight: 520,
    minWidth: mobile ? "100%" : 320,
    maxWidth: "40%",
    padding: theme.spacing(1),
    flexGrow: 1,
  }),
  container: (mobile) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    minHeight: mobile ? 200 : "auto",
    minWidth: 180,
  }),
}));

// Trello ticket on permissions: https://trello.com/c/Tfm5iRaP/103-add-permission-control-for-project-overview-page
const ProjectOverviewPage = ({ userId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const history = useHistory();
  const params = useParams();
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const projectId = params.projectId;
  const info = useProjectInfo({ projectId, userId });
  const title = useProjectTitle(projectId);
  const isJoinedProj = useUserInProject({ projectId, userId });
  const [projectIdToJoin, setProjectIdToJoin] = useState(null);

  const [showOrgSelectDialog, setShowOrgSelectDialog] = useState(false);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  // permissions control
  const {
    canDeleteProject,
    canWriteProject,
    canReadDevices,
    canWriteDevices,
    isSuperAdmin,
    isUserAdmin,
  } = useUserProjectPermissions({ userId, projectId });
  const tabs = useProjectOverviewTabs({
    projectId,
    tabKey: "OVERVIEW",
  });
  const deviceCount = useTotalDevicesCount(canReadDevices && projectId);
  const hasDevice = deviceCount > 0;
  const [projectLoading, setProjectLoading] = useState(false);

  const canLeave = info && getProjectCreatorId(info) !== userId; // anyone but the creator

  const menuDelete = {
    label: ProjectStrings.OVERVIEW_MENU_DELETE,
    onClick: () => {
      confirm({ message: ProjectStrings.OVERVIEW_PROJECT_DELETE_CONFIRM })
        .then(() => {
          // delete project and show toast
          deleteProject(projectId)
            .then(() => {
              // redirect to projects page
              history.push(getProjectsRoute());

              enqueueSnackbar(ProjectStrings.OVERVIEW_PROJECT_DELETED, {
                variant: "success",
              });
            })
            .catch((error) => {
              enqueueSnackbar(DefaultStrings.ERROR_MSG, {
                variant: "error",
              });
              console.warn(error);
            });
        })
        .catch((error) => {});
    },
    disabled: !canDeleteProject,
    color: "error",
  };

  const menuJoin = {
    label: ProjectStrings.OVERVIEW_MENU_JOIN,
    onClick: () => setProjectIdToJoin(projectId),
    disabled: isJoinedProj === true,
  };

  const menuLeave = {
    label: ProjectStrings.OVERVIEW_MENU_LEAVE,
    onClick: () => {
      confirm({ message: ProjectStrings.OVERVIEW_PROJECT_LEAVE_CONFIRM })
        .then(() => {
          leaveProject(projectId)
            .then(() => {
              // redirect to projects page
              history.push(getProjectsRoute());

              enqueueSnackbar(ProjectStrings.OVERVIEW_PROJECT_LEFT, {
                variant: "success",
              });
            })
            .catch((error) => {
              enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
              console.warn(error);
            });
        })
        .catch((error) => {});
    },
    disabled: !canLeave || isJoinedProj === false,
  };

  const menuTransfer = {
    label: ProjectStrings.OVERVIEW_MENU_TRANSFER,
    onClick: () => {
      setShowOrgSelectDialog(true);
    },
  };

  let overflowMenuItems = [menuLeave, menuDelete];

  if (isUserAdmin) {
    overflowMenuItems = [menuJoin, menuLeave, menuDelete];
  }

  if (isSuperAdmin) {
    overflowMenuItems = [menuLeave, menuDelete, menuTransfer];
  }

  const config = {
    userId,
    appBar: {
      overflow: overflowMenuItems,
      tabDefault: "OVERVIEW",
      tabs,
      title,
    },
  };

  const configDialog = {
    onClose: () => {
      setShowProjectDialog(false);
    },
    onConfirm: () => {
      setShowProjectDialog(false);
      console.debug("setProjectLoading", true);
      setProjectLoading(true);
    },
    onComplete: () => {
      console.debug("setProjectLoading", false);
      setProjectLoading(false);
    },
  };

  const configCard = {
    showBrand: true,
    showDescription: true,
    showPin: false,
    loading: projectLoading,
  };

  const configJoinDialog = {
    projectId: projectIdToJoin,
    userId,
    hideViewBtn: true,
    onClose: () => setProjectIdToJoin(null),
  };

  if (canWriteProject) {
    configCard.onClick = () => {
      setShowProjectDialog(true);
    };
  }

  const onOrgSelect = (organisation) => {
    setShowTransferDialog(true);
    setSelectedOrg(organisation);
  };

  const onOrgSelectDialogClose = () => {
    setShowOrgSelectDialog(false);
  };

  const onTransferDialogClose = () => {
    setShowTransferDialog(false);
    setSelectedOrg(null);
  };

  const onTransferDialogComplete = () => {
    setShowOrgSelectDialog(false);
    setShowTransferDialog(false);
    setSelectedOrg(null);
  };

  return (
    <MainPage config={config}>
      <div className={classes.base}>
        <div className={classes.infoContainer}>
          <ProjectCard
            userId={userId}
            projectId={projectId}
            config={configCard}
          />
        </div>
        <div className={classes.groupV}>
          <div className={classes.groupH}>
            {hasDevice && (
              <div
                className={classes.container}
                style={{
                  flexGrow: 3,
                  maxWidth: mobile ? " 100%" : "60%",
                }}
              >
                <ProjectDeviceStatCard
                  projectId={projectId}
                  canRead={canReadDevices}
                  canEdit={canWriteDevices}
                />
              </div>
            )}
            {hasDevice && (
              <div className={classes.container}>
                <PinCard projectId={projectId} userId={userId} />
              </div>
            )}
            <div className={classes.container}>
              <StatusCard userId={userId} projectId={projectId} />
            </div>
          </div>
          <div className={classes.groupH}>
            {hasDevice && (
              <div
                className={classes.container}
                style={{
                  flexGrow: 3,
                  maxWidth: mobile ? " 100%" : "60%",
                }}
              >
                <HardwareCard userId={userId} projectId={projectId} />
              </div>
            )}
            <div
              className={classes.container}
              style={{
                flexGrow: 2,
              }}
            >
              <ProjectNoteCard userId={userId} projectId={projectId} />
            </div>
          </div>
        </div>
      </div>
      <ProjectDialog
        projectId={projectId}
        open={showProjectDialog}
        config={configDialog}
      />
      <OrganisationsSelectDialog
        title={ProjectStrings.OVERVIEW_PROJECT_TRANSFER_TO_ORGANISATION_TITLE}
        open={showOrgSelectDialog}
        currentOrganisationId={info?.organisationId}
        onSelect={onOrgSelect}
        onClose={onOrgSelectDialogClose}
        showAllOrganisations
      />
      <ProjectTransferDialog
        open={showTransferDialog}
        projectId={projectId}
        organisation={selectedOrg}
        onComplete={onTransferDialogComplete}
        onClose={onTransferDialogClose}
      />
      <JoinDialog open={false} config={configJoinDialog} />
    </MainPage>
  );
};

export default withInProjectCheck(ProjectOverviewPage);
