import React, { useState, useRef } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { connect } from "react-redux";

import Dashboard from "./dashboard/Dashboard";
import Projects from "./projects/Projects";
import Team from "./team/Team";
import Activity from "./activity/Activity";
import Settings from "./settings/Settings";
import MainPage from "pages/MainPage";
import { OrganisationDialog, InviteUserDialog } from "ui/dialogs";

import { usePageConfig } from "./config";
import { ROUTES } from "route";
import { debounce } from "services/UiService";
import { TEAM_SORT_OPTION } from "utils/teamUtils";
import { withInOrganisationCheck } from "hocs/withInOrganisationCheck";
import Spinner from "ui/Spinner";

export const mapStateToProps = ({
  firebase: {
    auth: { uid },
  },
}) => ({ userId: uid });

const DashboardPage = connect(mapStateToProps)(Dashboard);
const TeamPage = connect(mapStateToProps, null, null, { forwardRef: true })(
  Team
);
const ActivityPage = connect(mapStateToProps)(Activity);
const SettingsPage = connect(mapStateToProps)(Settings);

const Index = ({ userId, match }) => {
  const params = useParams();
  const { organisationId } = params;
  const teamsRef = useRef(null);
  const projectsRef = useRef(null);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [orgLoading, setOrgLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [sortValue, setSortValue] = useState(TEAM_SORT_OPTION[1]);
  const [sortDescValue, setSortDescValue] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);

  const onCompleteHandle = () => {
    setOpenCreateDialog(false);
    setOrgLoading(false);
  };
  const onRequestHandle = () => setOrgLoading(true);
  const onRequestFailedHandle = () => setOrgLoading(false);
  const onCloseHandle = () => setOpenCreateDialog(false);
  const onUpdateHandle = () => setOpenCreateDialog(true);
  const onInviteOpenHandle = () => setOpenInvite(true);
  const onInviteCloseHandle = () => setOpenInvite(false);
  const onInviteSendHandle = () => setOpenInvite(false);

  const onSearchHandle = debounce((value) => {
    if (searchValue === value) return;
    setSearchValue(value);

    if (projectsRef.current) {
      projectsRef.current.doRequest({
        searchValue: value,
        sortValue,
        sortDesc: sortDescValue,
      });
    }
    if (teamsRef.current) {
      teamsRef.current.doRequest({
        searchValue: value,
        sortValue,
        sortDesc: sortDescValue,
      });
    }
  }, 300);

  const onSortHandle = (opt) => {
    let sortDesc = sortDescValue;
    if (sortValue === opt) {
      sortDesc = !sortDescValue;
      setSortDescValue(sortDesc);
    }

    setSortValue(opt);

    if (projectsRef.current) {
      projectsRef.current.doRequest({ sortValue: opt, sortDesc, searchValue });
    }
    if (teamsRef.current) {
      teamsRef.current.doRequest({ sortValue: opt, sortDesc, searchValue });
    }
  };

  const { config, requesting } = usePageConfig({
    userId,
    path: match.path,
    sortValue,
    sortDesc: sortDescValue,
    onSearch: onSearchHandle,
    onSort: onSortHandle,
    onInviteOpen: onInviteOpenHandle,
  });

  return (
    <MainPage config={{ userId, ...config }} match={match}>
      {requesting && <Spinner />}
      <Switch>
        <Route
          exact
          path={ROUTES.ORGANISATION_DASHBOARD}
          render={() => (
            <DashboardPage onUpdate={onUpdateHandle} loading={orgLoading} />
          )}
        />
        <Route
          exact
          path={ROUTES.ORGANISATION_PROJECTS}
          render={() => (
            <Projects
              ref={projectsRef}
              searchValue={searchValue}
              sortValue={sortValue}
              sortDesc={sortDescValue}
            />
          )}
        />
        <Route
          exact
          path={[ROUTES.ORGANISATION_TEAM, ROUTES.ORGANISATION_GUESTS]}
          render={() => (
            <TeamPage
              ref={teamsRef}
              searchValue={searchValue}
              sortValue={sortValue}
              sortDesc={sortDescValue}
            />
          )}
        />
        <Route
          exact
          path={[ROUTES.ORGANISATION_ACTIVITY]}
          component={ActivityPage}
        />
        <Route
          exact
          path={[ROUTES.ORGANISATION_SETTINGS]}
          component={SettingsPage}
        />
      </Switch>
      <OrganisationDialog
        organisationId={organisationId}
        open={openCreateDialog}
        onComplete={onCompleteHandle}
        onRequest={onRequestHandle}
        onRequestFailed={onRequestFailedHandle}
        onClose={onCloseHandle}
      />
      <InviteUserDialog
        organisationId={organisationId}
        open={openInvite}
        onClose={onInviteCloseHandle}
        onSend={onInviteSendHandle}
      />
    </MainPage>
  );
};

export const OrganisationPage = connect(mapStateToProps)(
  withInOrganisationCheck(Index)
);
