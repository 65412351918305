export const PROJECT_SORT_OPTION_FIELD = {
  SORT_OPTION_NAME: "name",
  SORT_OPTION_BRAND: "brand",
  SORT_OPTION_MOSTRECENT: "modified_date",
  SORT_OPTION_DATE: "creation_date",
};

export const PROJECT_SORT_OPTION = Object.keys(PROJECT_SORT_OPTION_FIELD);

export const KEYBOARD_KEYS = {
  ENTER: 13,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  SPACE: 32,
};

export const getProjectCreatorId = (info) => info && info.creatorId;
