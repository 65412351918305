import React from "react";

import { useOrganisationUsersStatistics } from "services/OrganisationService";
import MyCard from "../MyCard";
import Spinner from "ui/Spinner";
import { OrganisationStrings } from "strings";
import Content from "./Content";
import { TeamIcon } from "assets/icons";

const TeamStatCard = ({ organisationId, canRead }) => {
  const stats = useOrganisationUsersStatistics(organisationId);

  const configCard = {
    icon: <TeamIcon />,
    title: OrganisationStrings.OVERVIEW_TEAM_STAT_TITLE,
  };

  return (
    <MyCard config={configCard} canRead={canRead}>
      {typeof stats === "undefined" ? (
        <Spinner />
      ) : (
        <Content
          total={stats?.total || 0}
          admins={stats?.admins || 0}
          members={stats?.members || 0}
        />
      )}
    </MyCard>
  );
};

export default TeamStatCard;
