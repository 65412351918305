import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import WorkspacesOutlinedIcon from "@material-ui/icons/WorkspacesOutlined";
import SearchIcon from "@material-ui/icons/Search";

import {
  getAllOrganisations,
  useTotalOrganisationCountSuperAdmin,
} from "services/OrganisationService";
import { useMobileLayout } from "hooks/uiHooks";
import { useStyles } from "./style";
import { OrganisationStrings } from "strings";
import Spinner from "ui/Spinner";
import { ORGANISATION_SORT_OPTION_FIELD } from "utils/organisationUtils";
import InfiniteScroll from "ui/InfiniteScroll";
import { EmptyData } from "ui/emptyData";
import { OrganisationSimpleCard } from "ui/organisationSimpleCard";
import { getOrganisationDashboardRoute } from "route";

const Organisations = forwardRef((props, ref) => {
  const history = useHistory();
  const mobile = useMobileLayout();
  const classes = useStyles({ mobile });

  const [startAfter, setStartAfter] = useState();
  const [organisationsIds, setOrganisationsIds] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [totalOrganisations, setTotalOrganisations] = useState();
  const orgsSuperAdminTotalCount = useTotalOrganisationCountSuperAdmin();

  const resetData = () => {
    setIsFetchedAll(false);
    setIsRequestSent(true);
    setStartAfter();
  };

  const getParams = useCallback((params) => {
    const { searchValue, sortDesc, lastKey } = params;
    const orderBy =
      params.sortValue && params.sortValue in ORGANISATION_SORT_OPTION_FIELD
        ? ORGANISATION_SORT_OPTION_FIELD[params.sortValue]
        : ORGANISATION_SORT_OPTION_FIELD.SORT_OPTION_DATE;

    return {
      limit: 20,
      orderBy,
      searchValue,
      lastKey,
      orderDesc: sortDesc,
    };
  }, []);

  const onSelectHandle = (orgInfo) => {
    history.push(getOrganisationDashboardRoute(orgInfo.organisationId));
  };

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        ...props,
        ...params,
      });

      try {
        const response = await getAllOrganisations(requestParams);
        const { items, total } = response.result;
        setTotalOrganisations(total);

        if (items.length === 0) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setOrganisationsIds([...organisationsIds, ...items]);
        } else {
          setOrganisationsIds(items);
          setIsRequestSent(false);
        }

        if (items.length) {
          setStartAfter(items[items.length - 1]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [getParams, isRequestSent, organisationsIds, props]
  );

  useEffect(() => {
    // initial request on page loaded
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // do request if total count changed for organisations
    if (
      typeof totalOrganisations !== "undefined" &&
      typeof organisationTotalCount !== "undefined" &&
      totalOrganisations !== orgsSuperAdminTotalCount
    ) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalOrganisations, orgsSuperAdminTotalCount]);

  useImperativeHandle(
    ref,
    () => ({
      doRequest: (opt) => {
        resetData();
        fetchData(opt);
      },
    }),
    [fetchData]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  const config = {
    onLoadMore: () => {
      if (isFetchedAll) return;

      fetchData({ lastKey: startAfter });
    },
  };

  const renderContent = () => {
    if (typeof organisationsIds === "undefined") return <Spinner />;

    if (!organisationsIds.length && !isRequestSent)
      return (
        <EmptyData
          title={
            props.searchValue
              ? OrganisationStrings.LIST_NO_FOR_SEARCH
              : OrganisationStrings.LIST_NO_ORGANISATIONS
          }
          icon={props.searchValue ? <SearchIcon /> : <WorkspacesOutlinedIcon />}
        />
      );

    return (
      <InfiniteScroll config={config} size={organisationsIds.length}>
        <div className={classes.main}>
          {organisationsIds.map((organisationId) => (
            <div key={organisationId} className={classes.itemContainer}>
              <OrganisationSimpleCard
                organisationId={organisationId}
                actionTitle={OrganisationStrings.CARD_ITEM_VIEW}
                onSelect={onSelectHandle}
              />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    );
  };

  return <div className={classes.root}>{renderContent()}</div>;
});

export default Organisations;
