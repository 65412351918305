import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Projects from "./Projects";
import ProjectOverview from "./ProjectOverview";
import ProjectMedia from "./ProjectMedia";
import ProjectDevices from "./ProjectDevices";
import ProjectAnalytics from "./ProjectAnalytics";
import ProjectTeam from "./ProjectTeam";
import {
  getProjectOverviewRoute,
  getProjectMediaRoute,
  getProjectDevicesRoute,
  getProjectAnalyticsRoute,
  getProjectTeamRoute,
  ROUTES,
} from "route";
import { ProjectStrings } from "../../strings";

const mapStateToProps = ({
  firebase: {
    auth: { uid },
  },
}) => ({ userId: uid });

export const ProjectsPage = connect(mapStateToProps)(Projects);
export const ProjectOverviewPage = connect(mapStateToProps)(ProjectOverview);
export const ProjectMediaPage = connect(mapStateToProps)(ProjectMedia);
export const ProjectDevicesPage = connect(mapStateToProps)(ProjectDevices);
export const ProjectAnalyticsPage = connect(mapStateToProps)(ProjectAnalytics);
export const ProjectTeamPage = connect(mapStateToProps)(ProjectTeam);

const tabsOverviewConfig = {
  OVERVIEW: {
    label: ProjectStrings.TAB_TITLE_OVERVIEW,
    routeFunc: getProjectOverviewRoute,
  },
  MEDIA: {
    label: ProjectStrings.TAB_TITLE_MEDIA,
    routeFunc: getProjectMediaRoute,
  },
  DEVICES: {
    label: ProjectStrings.TAB_TITLE_DEVICES,
    routeFunc: getProjectDevicesRoute,
  },
  ANALYTICS: {
    label: ProjectStrings.TAB_TITLE_ANALYTICS,
    routeFunc: getProjectAnalyticsRoute,
  },
  TEAM: {
    label: ProjectStrings.TAB_TITLE_TEAM,
    routeFunc: getProjectTeamRoute,
  },
};

const tabsProjectsConfig = (selectedOrganisationId) => {
  const tabs = {
    MY_PROJECTS: {
      key: "MY_PROJECTS",
      label: ProjectStrings.TAB_TITLE_MY_PROJECTS,
      path: ROUTES.PROJECTS,
    },
  };
  if (selectedOrganisationId) {
    tabs["ALL_PROJECTS"] = {
      key: "ALL_PROJECTS",
      label: ProjectStrings.TAB_TITLE_ALL_PROJECTS,
      path: ROUTES.PROJECTS_ALL_PROJECTS,
    };
  }
  return tabs;
};

// return an array of tabs to be used by MyAppBar
export const useProjectOverviewTabs = ({
  projectId,
  tabKey,
  canReadDevices,
}) => {
  const history = useHistory();
  return Object.entries(tabsOverviewConfig).map(([key, cfg]) => ({
    key,
    label: cfg.label,
    onClick: (oldTab, newTab) => {
      history.push(cfg.routeFunc(projectId));
    },
  }));
};

export const useProjectsTabs = (selectedOrganisationId) => {
  const history = useHistory();

  return Object.entries(tabsProjectsConfig(selectedOrganisationId)).map(
    ([key, cfg]) => ({
      key,
      label: cfg.label,
      onClick: () => {
        history.push(cfg.path);
      },
    })
  );
};
