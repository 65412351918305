import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

export const MenuStyled = withStyles((theme) => ({
  paper: {
    borderRadius: "8px",
    backgroundColor: theme.palette.background.default,
    minWidth: "260px",
    "& ul": {
      backgroundColor: theme.palette.common.white,
      padding: 0,
    },
  },
}))((props) => (
  <Menu
    variant="menu"
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: -60,
      horizontal: "left",
    }}
    keepMounted
    {...props}
  />
));

export const MenuItemStyled = withStyles((theme) => ({
  root: {
    padding: "16px 24px",
    display: "flex",
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    "&.custom:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(MenuItem);

export const OrgButton = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    marginTop: "5px",
    marginLeft: "12px",
    borderRadius: "50%",
    minWidth: "auto",
    width: "36px",
    height: "36px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    overflow: "hidden",
    "&:hover": {
      boxShadow: "none",
    },
  },
}))(Button);

export const Header = withStyles((theme) => ({
  root: {
    display: "flex",
    padding: "12px",
    backgroundColor: theme.palette.background.default,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "none",
    cursor: "default",
    pointerEvents: "none",
  },
}))(MenuItem);

export const OrgCircleName = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    color: theme.palette.common.white,
    textTransform: "capitalize",
    fontSize: "2rem",
    fontWeight: 700,
    backgroundColor: theme.palette.primary.main,
    width: "60px",
    height: "60px",
    marginBottom: "12px",
    lineHeight: 1,
  },
}))(({ classes, children }) => <div className={classes.root}>{children}</div>);

export const OrgImage = withStyles({
  root: {
    height: "inherit",
    width: "inherit",
    borderRadius: "50%",
  },
})(({ classes, src, alt }) => (
  <img className={classes.root} src={src} alt={alt} />
));

export const IconWrapper = withStyles((theme) => ({
  root: {
    display: "flex",
    height: "36px",
    width: "36px",
    marginRight: "10px",
    borderRadius: "50%",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[400],
    justifyContent: "center",
    alignItems: "center",
  },
}))(({ classes, children }) => <div className={classes.root}>{children}</div>);

export const SeeAllButton = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "start",
    color: theme.palette.primary.main,
    width: "100%",
    textAlign: "left",
    padding: "16px 24px",

    "&:hover": {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  },
}))(Button);

export const LogoInMenuWrapper = withStyles((theme) => ({
  root: {
    height: "36px",
    width: "36px",
  },
}))(Box);

export const Title = withStyles((theme) => ({
  root: {
    fontSize: "14px",
    fontWeight: 700,
  },
}))(Typography);

export const UserRole = withStyles((theme) => ({
  root: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#777777",
  },
}))(Typography);
