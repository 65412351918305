import React, { useState, useMemo, useRef } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";
import QueuePlayNextOutlinedIcon from "@material-ui/icons/QueuePlayNextOutlined";

import { joinProject } from "services/ApiService";
import { PROJECT_SORT_OPTION } from "utils/projectUtils";
import MainPage from "../MainPage";
import MetaTags from "ui/MetaTags";
import ProjectList from "ui/ProjectList";
import ProjectDialog from "ui/dialogs/ProjectDialog";
import Spinner from "ui/Spinner";
import { DefaultStrings, HomeStrings, ProjectStrings } from "strings";
import { useCurrentOrganisation } from "services/UserService";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import { JoinDialog } from "ui/dialogs";
import { useProjectsTabs } from "./";
import { getProjectOverviewRoute, ROUTES } from "route";
import { debounce } from "services/UiService";

const ProjectsPage = ({ userId, match }) => {
  const matchNew = !!useRouteMatch(ROUTES.PROJECT_NEW);
  const matchAllProjects = !!useRouteMatch(ROUTES.PROJECTS_ALL_PROJECTS);
  const selectedOrganisationId = useCurrentOrganisation();

  const tabs = useProjectsTabs(selectedOrganisationId);
  const history = useHistory();
  const projectsListRef = useRef(null);

  const [searchValue, setSearchValue] = useState(null);
  const [sortValue, setSortValue] = useState(PROJECT_SORT_OPTION[0]);
  const [sortDescValue, setSortDescValue] = useState(false);
  const [showJoinDialog, setShowJoinDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(matchNew);
  const [showProjectList, setShowProjectList] = useState(true);
  const [projectIdToJoin, setProjectIdToJoin] = useState(null);
  const [approvalRequested, setApprovalRequested] = useState(false);

  const onJoinCloseHandle = () => {
    setApprovalRequested(false);
    setProjectIdToJoin(null);
    setShowJoinDialog(false);
  };

  const { canWriteProjects, isSuperAdmin } = useUserOrganisationPermissions({
    userId,
    organisationId: selectedOrganisationId,
  });

  const configSortOptions = useMemo(
    () =>
      PROJECT_SORT_OPTION.map((opt) => ({
        label: ProjectStrings[opt],
        desc: sortDescValue,
        selected: opt === sortValue,
        onClick: () => {
          let sortDesc = !sortDescValue;
          if (!sortValue || sortValue === opt) {
            setSortDescValue(!sortDescValue);
          } else {
            sortDesc = sortDescValue;
          }

          setSortValue(opt);

          projectsListRef.current.doRequest({ sortValue: opt, sortDesc });
        },
      })),
    [sortDescValue, sortValue]
  );

  const configPage = {
    userId,
    appBar: {
      onSearch: debounce((value) => {
        let valueToSearch = value;
        if (valueToSearch && valueToSearch.length > 1) {
          setSearchValue(valueToSearch);
        } else {
          valueToSearch = null;
          setSearchValue(null);
        }

        if (valueToSearch === searchValue) return;

        projectsListRef.current.doRequest({
          searchValue: valueToSearch,
          sortValue,
          sortDesc: sortDescValue,
        });
      }, 300),
      sort: configSortOptions,
      title: DefaultStrings.TITLE,
      tabDefault:
        matchAllProjects && tabs.length > 1 ? tabs[1].key : tabs[0].key,
      tabs,
    },
    actionButton: {
      actions: [
        {
          icon: <AddToQueueOutlinedIcon />,
          name: ProjectStrings.ADD_PROJECT_DIALOG_CREATE,
          onClick: () => setShowCreateDialog(true),
          disabled: selectedOrganisationId && !canWriteProjects,
        },
        {
          icon: <QueuePlayNextOutlinedIcon />,
          name: ProjectStrings.ADD_PROJECT_DIALOG_JOIN,
          onClick: () => setShowJoinDialog(true),
        },
      ],
    },
  };

  const configProjectDialog = {
    onClose: () => setShowCreateDialog(false),
    onConfirm: () => {
      setShowCreateDialog(false);
      setShowProjectList(false);
    },
    onComplete: () => {
      setShowProjectList(true);
      projectsListRef.current.doRequest({
        searchValue,
        sortValue,
        sortDesc: sortDescValue,
      });
    },
  };

  const configProjectList = {
    organisationId: selectedOrganisationId,
    showOrgProjects: matchAllProjects,
    onClick: (projectId, joined) => {
      if (!joined && isSuperAdmin === false) {
        // open join pop-up window
        setProjectIdToJoin(projectId);
      }
    },
    clickToOpen: true,
    onAdd: () => setShowCreateDialog(true),
  };

  const configJoinDialog = {
    projectId: projectIdToJoin,
    userId,
    approvalRequested,
    onOk: () => {
      onJoinCloseHandle();
    },
    onClose: (id) => {
      onJoinCloseHandle();
      setProjectIdToJoin(id);
    },
    onJoin: async (projectId, joinCode, isApprovalRequired) => {
      await joinProject({ projectId, joinCode });

      if (isApprovalRequired) {
        setApprovalRequested(true);
        return;
      }

      setProjectIdToJoin(null);
      history.push(getProjectOverviewRoute(projectId));
    },
  };

  return (
    <>
      <MetaTags
        title={HomeStrings.META_TITLE_PROJECTS}
        description={HomeStrings.META_DESCRIPTION_PROJECTS}
      />
      <MainPage config={configPage} match={match}>
        {showProjectList ? (
          <ProjectList
            ref={projectsListRef}
            config={configProjectList}
            searchValue={searchValue}
            sortValue={sortValue}
            sortDesc={sortDescValue}
          />
        ) : (
          <Spinner />
        )}
        <ProjectDialog
          userId={userId}
          organisationId={selectedOrganisationId}
          open={showCreateDialog}
          config={configProjectDialog}
        />
        <JoinDialog open={showJoinDialog} config={configJoinDialog} />
      </MainPage>
    </>
  );
};

export default ProjectsPage;
